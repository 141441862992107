import React, { useReducer, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './component/Loading';
import { API_URL } from '../config';
import { componentTypes } from './config/componentTypes';
import { safeParseJSON } from './utils/safeParseJSON';
import { useAppData } from './component/AppDataContext';
import { useNavigate } from 'react-router-dom';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';

// ======================
// Initial State & Reducer
// ======================
const initialState = { rows: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'LOAD_PAGE_DATA': {
      // The API response for a post looks like:
      // {
      //   "page": {
      //     "page_builder_rows": [ { ... }, { ... } ]
      //   },
      //   "blog": { ... },
      //   "post_categories": [ ... ]
      //   ...
      // }

      const postData = action.payload; // The entire response object
      const pageBuilderRows = postData.page?.page_builder_rows || [];

      const rows = pageBuilderRows.map((row) => ({
        id: row.id,
        disabled: row.disabled,
        styles: safeParseJSON(row.styles, {}),

        sections: row.page_builder_sections.map((section) => ({
          id: section.id,
          disabled: section.disabled,
          layout_key: section.layout_key,
          styles: safeParseJSON(section.styles, {}),

          columns: section.page_builder_columns.map((column) => ({
            class: column.class,
            styles: safeParseJSON(column.styles, {}),

            components: column.page_builder_components.map((component) => ({
              type: component.type,
              props: safeParseJSON(component.props, {}),
            })),

            subsections: column.page_builder_subsections.map((subsection) => ({
              id: subsection.id,
              disabled: subsection.disabled,
              layout_key: subsection.layout_key,
              // If you have subsection styles, parse them here, e.g. safeParseJSON(subsection.styles, {})

              subcolumns: subsection.page_builder_subcolumns.map((subcolumn) => ({
                class: subcolumn.class,
                styles: safeParseJSON(subcolumn.styles, {}),

                subcomponents: subcolumn.page_builder_subcomponents.map((subcomp) => ({
                  type: subcomp.type,
                  props: safeParseJSON(subcomp.props, {}),
                })),
              })),
            })),
          })),
        })),
      }));

      return { ...state, rows };
    }
    default:
      return state;
  }
}

// ======================
// Fetch Post Data
// ======================
const fetchPostData = async (postId, subdomain) => {
  // Adjust your endpoint if needed:
  const response = await fetch(`${API_URL}/post-builder/${postId}?account_key=${subdomain}`);
  if (!response.ok) {
    throw new Error('Failed to fetch post data');
  }
  const data = await response.json();
  return data;
};

// ======================
// Helper: Row has Non-FullScreen Components?
// ======================
function rowHasNonFullScreenComponents(row) {
  for (const section of row.sections) {
    if (section.disabled === 1) continue;

    for (const column of section.columns) {
      // top-level components
      for (const comp of column.components) {
        if (!(comp.type === 'carousel' && comp.props.isFullScreen)) {
          return true;
        }
      }

      // sub-sections
      for (const subsection of column.subsections) {
        if (subsection.disabled === 1) continue;

        for (const subcol of subsection.subcolumns) {
          for (const subcomp of subcol.subcomponents) {
            if (!(subcomp.type === 'carousel' && subcomp.props.isFullScreen)) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
}

// ======================
// Main CustomPosts Component
// ======================
function CustomPosts({ postId: propPostId }) {
  const { postId: paramPostId } = useParams();
  const idToLoad = propPostId || paramPostId;
  const { subdomain } = useAppData();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Optionally store the entire post object for other uses
  const [currentPost, setCurrentPost] = useState(null);

  useEffect(() => {
    if (idToLoad) {
      setIsLoading(true);
      fetchPostData(idToLoad, subdomain)
        .then((postData) => {
          setCurrentPost(postData);
          dispatch({ type: 'LOAD_PAGE_DATA', payload: postData });
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError(err.message);
          setIsLoading(false);
        });
    }
  }, [idToLoad, subdomain]);

  // Identify all full-screen carousels
  const fullScreenCarousels = state.rows.flatMap((row) =>
    row.sections.flatMap((section) =>
      section.columns.flatMap((column) =>
        column.components.filter(
          (comp) => comp.type === 'carousel' && comp.props.isFullScreen
        )
      )
    )
  );

  // Filter rows that have non-fullscreen content
  const filteredRows = state.rows.filter((row) => rowHasNonFullScreenComponents(row));

  return (
    <div className="custom-posts-container">
      {isLoading && <Loading />}
      {error && <div className="alert alert-danger">Error: {error}</div>}

      {/* 1) Render any full-screen carousels */}
      {fullScreenCarousels.map((carouselComp, idx) => (
        <div key={`fullscreen-carousel-${idx}`} className="carousel-full-screen">
          {React.createElement(componentTypes[carouselComp.type], {
            ...carouselComp.props,
          })}
        </div>
      ))}

      {/* 2) Render all other rows */}
      {filteredRows.map((row) =>
        row.disabled !== 1 ? <Row key={row.id} row={row} /> : null
      )}
    </div>
  );
}

// ======================
// Sub-components
// ======================

function Row({ row }) {
  const rowStyles = useResponsiveStyles(row.styles || {});
  const { overlayColor, overlayOpacity, ...restOfStyles } = rowStyles;

  const containerStyles = {
    position: 'relative',
    ...restOfStyles,
  };

  return (
    <div className="row-wrap" style={containerStyles}>
      {/* Overlay if set */}
      {overlayColor && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: overlayColor,
            opacity: parseFloat(overlayOpacity) || 1,
            pointerEvents: 'none',
          }}
        />
      )}

      {row.sections.map((section) => (
        <Section 
            key={section.id} 
            section={section}    
            overlayColor={rowStyles.overlayColor}
            overlayOpacity={rowStyles.overlayOpacity}
        />
      ))}
    </div>
  );
}

// Nested Section Component within the same file
function Section({ section, overlayColor, overlayOpacity }) {
    const appliedStyles = useResponsiveStyles(section.styles || {});
    
    const containerStyles = {
       ...appliedStyles,
       ...(overlayColor ? { position: 'relative' } : {})
    };

  return (
    <div className="section-wrap" style={containerStyles}>
      <div className="container">
        <div className="row">
          {section.columns.map((column, idx) => (
            <Column key={idx} column={column} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Column({ column }) {
  const appliedStyles = useResponsiveStyles(column.styles || {});

  return (
    <div className={`${column.class} p-3-custom`} style={appliedStyles}>
      {/* 1) Subsections */}
      {column.subsections.map((subsection, idx) =>
        subsection.disabled !== 1 ? (
          <Subsection key={subsection.id || idx} subsection={subsection} />
        ) : null
      )}

      {/* 2) Top-level components */}
      {column.components.map((comp, idx) => {
        if (comp.type === 'carousel' && comp.props.isFullScreen) {
          // Already rendered as full-screen
          return null;
        }
        return <ComponentRenderer key={idx} type={comp.type} props={comp.props} />;
      })}
    </div>
  );
}

function Subsection({ subsection }) {
  return (
    <div className="row">
      {subsection.subcolumns.map((subcol, idx) => (
        <Subcolumn key={subcol.id || idx} subcolumn={subcol} />
      ))}
    </div>
  );
}

function Subcolumn({ subcolumn }) {
  const appliedStyles = useResponsiveStyles(subcolumn.styles || {});
  return (
    <div className={`${subcolumn.class} p-3-custom`} style={appliedStyles}>
      {subcolumn.subcomponents.map((subcomp, idx) => (
        <ComponentRenderer key={idx} type={subcomp.type} props={subcomp.props} />
      ))}
    </div>
  );
}

/** Dynamically renders a component by type */
function ComponentRenderer({ type, props }) {
  const Comp = componentTypes[type];
  if (!Comp) {
    console.error(`Undefined component type: ${type}`);
    return null;
  }
  return <Comp {...props} />;
}

export default CustomPosts;
