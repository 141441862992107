// src/components/Sidebar.js

import React, { useEffect, useState } from 'react';
import DraggableComponent from './DraggableComponent';
import { exampleProps } from '../config/exampleProps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
  faImage,
  faHeading,
  faQuoteRight,
  faMagic,
  faVideo,
  faTh,
  faFolder,
  faLayerGroup,
  faSpinner,
  faImages,
  faArrowsAltV,
  faMinus,
  faTag,
  faExclamationTriangle,
  faAddressCard,
  faArrowsUpDownLeftRight,
  faList,
  faStar,
  faDollarSign,
  faMapMarkerAlt,
  faQuestionCircle,
  faShareAlt,
  faUsers,
  faEnvelope,
  faStore  
} from '@fortawesome/free-solid-svg-icons';

import Accordion from 'react-bootstrap/Accordion';
import { useAppData } from './AppDataContext'; // Adjust path as needed

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000'; // or import from config

const Sidebar = () => {
  const { subdomain } = useAppData();          // Access subdomain
  const [templates, setTemplates] = useState([]); // State for fetched templates

  // 1. Fetch saved templates from your backend
  useEffect(() => {
    if (!subdomain) return;

    fetch(`${API_URL}/templates?account_key=${subdomain}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch templates');
        }
        return res.json();
      })
      .then((data) => {
        // Adjust based on your API response shape
        // e.g., if the data is in `data.data` or `data.templates`
        const fetchedTemplates = data.data || data.templates || [];
        setTemplates(fetchedTemplates);
      })
      .catch((error) => {
        console.error('Error fetching templates:', error);
      });
  }, [subdomain]);

  
 // 2. Convert saved templates into components for the "Saved Templates" category
  const savedTemplatesCategory = {
    title: 'Saved Templates',
    components: templates.map((template) => ({
      // In DraggableComponent, we pass type & props.
      // The 'type' can be the same as your builder logic expects (e.g. "accordion", "heading", etc.)
      type: template.type,
      props: template.props,
      // We'll default to a folder icon or something else for all saved templates
      icon: faFolder,
      // Label them by the name from the database
      label: template.name,
    })),
  };

  // 3. Existing categories
  const componentCategories = [
    {
      title: 'Text Widgets',
      components: [
        { type: 'heading', props: exampleProps.heading, icon: faHeading, label: 'Heading' },
        { type: 'text', props: exampleProps.text, icon: faFont, label: 'Text' },
        { type: 'richText', props: exampleProps.richText, icon: faFont, label: 'Rich Text' },
        { type: 'blockquote', props: exampleProps.blockquote, icon: faQuoteRight, label: 'Blockquote' },
        { type: 'button', props: exampleProps.button, icon: faMagic, label: 'Button' },
      ],
    },
    {
      title: 'Media Widgets',
      components: [
        { type: 'image', props: exampleProps.image, icon: faImage, label: 'Image' },
        { type: 'video', props: exampleProps.video, icon: faVideo, label: 'Video' },
        { type: 'gallery', props: exampleProps.gallery, icon: faTh, label: 'Gallery' },
      ],
    },
    {
      title: 'Interactive Widgets',
      components: [
        { type: 'tabs', props: exampleProps.tabs, icon: faFolder, label: 'Tabs' },
        { type: 'accordion', props: exampleProps.accordion, icon: faLayerGroup, label: 'Accordion' },
        { type: 'progress', props: exampleProps.progress, icon: faSpinner, label: 'Progress' },
        { type: 'carousel', props: exampleProps.carousel, icon: faImages, label: 'Carousel' },
      ],
    },
    {
      title: 'Layout Widgets',
      components: [
        { type: 'spacer', props: exampleProps.spacer, icon: faArrowsAltV, label: 'Spacer' },
        { type: 'divider', props: exampleProps.divider, icon: faMinus, label: 'Divider' },
        { type: 'badge', props: exampleProps.badge, icon: faTag, label: 'Badge' },
        { type: 'alert', props: exampleProps.alert, icon: faExclamationTriangle, label: 'Alert' },
        { type: 'breadcrumb', props: exampleProps.breadcrumb, icon: faFolder, label: 'Breadcrumb' },
      ],
    },
    {
      title: 'Cards and Display Widgets',
      components: [
        { type: 'imageOverlayCard', props: exampleProps.imageOverlayCard, icon: faImage, label: 'Image Overlay Card' },
        { type: 'headerFooterCard', props: exampleProps.headerFooterCard, icon: faAddressCard, label: 'Header Footer Card' },
        { type: 'horizontalCard', props: exampleProps.horizontalCard, icon: faArrowsUpDownLeftRight, label: 'Horizontal Card' },
        { type: 'listGroupCard', props: exampleProps.listGroupCard, icon: faList, label: 'List Group Card' },
        { type: 'iconCard', props: exampleProps.iconCard, icon: faStar, label: 'Icon Card' },
        { type: 'iconComponent', props: exampleProps.iconCard, icon: faStar, label: 'Icon Only' },
        { type: 'testimonialComponent', props: exampleProps.testimonial, icon: faQuoteRight, label: 'Testimonial' },
      ],
    },
    {
      title: 'Business Widgets',
      components: [
        { type: 'pricingTable', props: exampleProps.pricingTable, icon: faDollarSign, label: 'Pricing Table' },
        { type: 'map', props: exampleProps.map, icon: faMapMarkerAlt, label: 'Map' },
        { type: 'faq', props: exampleProps.faq, icon: faQuestionCircle, label: 'FAQ' },
        { type: 'socialMediaIcons', props: exampleProps.socialMediaIcons, icon: faShareAlt, label: 'Social Media Icons' },
        { type: 'teamSection', props: exampleProps.teamSection, icon: faUsers, label: 'Team Section' },
      ],
    },
    {
      title: 'Blog Widgets',
      components: [
        { type: 'blogs', props: exampleProps.blogListing, icon: faTh, label: 'Blog' },
        { type: 'blogList', props: exampleProps.blogListing, icon: faTh, label: ' Blog Posts' },
        { type: 'blogPostTitle', props: exampleProps.blogListing, icon: faTh, label: ' Blog Post Title' },
        { type: 'blogPostBody', props: exampleProps.blogListing, icon: faTh, label: ' Blog Post Body' },
        { type: 'blogPostExcerpt', props: exampleProps.blogListing, icon: faTh, label: ' Blog Post Excerpt' },

      ],
    },
    {
      title: 'Header Footer Widgets',
      components: [
        { type: 'menus', props: exampleProps.menus, icon: faTh, label: 'Menus' },
        { type: 'brandLogo', props: exampleProps.brandLogo, icon: faImage, label: ' Brand Logo' },
      ],
    },
    {
      title: 'Form Widgets',
      components: [
        { type: 'contactForm', props: exampleProps.contactForm, icon: faEnvelope, label: 'Contact Form' },
      ],
    },

    {
      title: 'STORE',
      components: [
        { type: 'productList', props: exampleProps.productList, icon: faStore, label: 'Product List' },
      ],
    },
  ];

 // 4. Combine the “Saved Templates” category at the top only if there is at least one saved template
  const allCategories =
    templates.length > 0
      ? [savedTemplatesCategory, ...componentCategories]
      : componentCategories;

  return (
    <div className="components-list">
      <Accordion defaultActiveKey="0" flush>
        {allCategories.map((category, catIdx) => (
          <Accordion.Item eventKey={String(catIdx)} key={catIdx}>
            <Accordion.Header>{category.title}</Accordion.Header>
            <Accordion.Body>
              <div className="dragable-main">
                {category.components.map((component, cIdx) => (
                  <DraggableComponent
                    key={cIdx}
                    type={component.type}
                    props={component.props}
                  >
                    <FontAwesomeIcon icon={component.icon} /> {component.label}
                  </DraggableComponent>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Sidebar;
