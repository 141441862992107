import React, { useState } from 'react';
import { Form, Button, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCheckCircle, faStar, faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons';

const ListGroupForm = ({
    formValues,
    setFormValues,
    handleAddJsonArrayItem,
    handleRemoveJsonArrayItem,
    handleSaveToDatabase,
}) => {
    const iconMap = {
        faCheckCircle: faCheckCircle,
        faStar: faStar,
        faAngleRight: faAngleRight,
        faCircle: faCircle,
    };

    const renderListGroupItems = (fieldName) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3 list-item-wrap li-item-form">
                    <InputGroup>
                        {/* Text Input */}
                        <Form.Control
                            type="text"
                            placeholder="Enter item text"
                            value={item.text || ''}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].text = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />

                        {/* Icon Dropdown */}
                        <DropdownButton
                            title={<FontAwesomeIcon icon={iconMap[item.icon] || faAngleRight} />}
                            variant="secondary"
                            onSelect={(icon) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].icon = icon;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        >
                            {Object.keys(iconMap).map((iconKey) => (
                                <Dropdown.Item key={iconKey} eventKey={iconKey}>
                                    <FontAwesomeIcon icon={iconMap[iconKey]} /> {iconKey.replace('fa', '')}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        {/* Remove Button */}
                        <Button
                            variant="danger"
                            className="position-set ls-grp-danger"
                            onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </InputGroup>
                </div>
            ))}
            <Button
                className="add-more-media"
                variant="primary"
                onClick={() => handleAddJsonArrayItem(fieldName, { text: '', icon: 'faAngleRight' })}
            >
                <FontAwesomeIcon icon={faPlus} /> Add Item
            </Button>
        </>
    );

    return (
        <div className="list-group-form">
            {/* Header */}
            <Form.Group controlId="listGroupHeader" className="mb-3 carousel-wrap pb-3">
                <Form.Label>Header</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter header text"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
            </Form.Group>

            {/* Items */}
            <Form.Group controlId="listGroupItems" className="carousel-wrap pb-3">
                <Form.Label>Items</Form.Label>
                {renderListGroupItems('items')}
            </Form.Group>

            
        </div>
    );
};

export default ListGroupForm;
