import React, { useState, useEffect } from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { API_URL } from '../../../config';
import { useAppData } from '../AppDataContext';
import { useLocation } from 'react-router-dom';
import { useResponsiveStyles } from './useResponsiveStyles';


const BlogPostTitle = ({ styles = {} }) => {

  const appliedStyles = useResponsiveStyles(styles);


  const { subdomain } = useAppData();
  const location = useLocation();
  const urlSegments = location.pathname.split('/');
  const effectivePostId = urlSegments[urlSegments.length - 1];
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Effective postId:", effectivePostId);
  }, [effectivePostId]);

  useEffect(() => {
    if (!effectivePostId || !subdomain) return;
    setLoading(true);
    fetch(`${API_URL}/blog-post?account_key=${subdomain}&post_id=${effectivePostId}&field=title`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch blog post data.');
        }
        return res.json();
      })
      .then((data) => {
        // Access the title from data.data.title based on the API response.
        setTitle(data.data.title);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [effectivePostId, subdomain]);

  if (loading) return <Spinner animation="border" size="sm" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return <div style={appliedStyles.container}><h1 style={appliedStyles.text}>{title}</h1></div>;
};

export default BlogPostTitle;
