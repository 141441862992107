import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_URL } from '../../config';

const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
  const [menus, setMenus] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('');
  const [headerMenuFontColor, setHeaderMenuFontColor] = useState('');
  const [customStyles, setCustomStyles] = useState('');

  // Only storing IDs for website header and footer custom URLs
  const [websiteHeaderCustomUrl, setWebsiteHeaderCustomUrl] = useState('');
  const [websiteFooterCustomUrl, setWebsiteFooterCustomUrl] = useState('');

  // Get subdomain from the hostname (simplified for demonstration)

  const subdomain = window.location.hostname.split('.')[0];
// const subdomain = 'Renew_My_Tech'; // for demo


  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        // Fetch menus, meta-data, header custom URL and footer custom URL concurrently.
        const [menuResponse, metaResponse, headerResponse, footerResponse] = await Promise.all([
          fetch(`${API_URL}/menus?account_key=${subdomain}`),
          fetch(`${API_URL}/meta-data?account_key=${subdomain}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // Note: Removed website_header_custom_url and website_footer_custom_url from identifiers
            body: JSON.stringify({
              identifiers: [
                'website_logo',
                'header_background_color',
                'header_menu_font_color',
                'custom_styles',
              ],
            }),
          }),
          fetch(`${API_URL}/custom-header-footer-url?type=header&account_key=${subdomain}`),
          fetch(`${API_URL}/custom-header-footer-url?type=footer&account_key=${subdomain}`),
        ]);

        // Parse responses
        const menuData = await menuResponse.json();
        const metaData = await metaResponse.json();
        const headerData = await headerResponse.json();
        const footerData = await footerResponse.json();

        // Menus
        if (menuData) {
          setMenus(Object.values(menuData));
        }

        // Meta Data (without header/footer custom URL)
        metaData.forEach((item) => {
          switch (item.identifier) {
            case 'website_logo':
              setLogoUrl(item.data);
              break;
            case 'header_background_color':
              setHeaderBackgroundColor(item.data);
              break;
            case 'header_menu_font_color':
              setHeaderMenuFontColor(item.data);
              break;
            case 'custom_styles':
              setCustomStyles(item.data);
              break;
            default:
              break;
          }
        });

        // Set website header and footer custom URL from separate API endpoints.
        // (Assuming the response structure is { id: <customUrl> }.)
        setWebsiteHeaderCustomUrl(headerData.id);
        setWebsiteFooterCustomUrl(footerData.id);

        setIsDataLoading(false);
      } catch (error) {
        console.error('Failed to fetch app data:', error);
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, [subdomain]);

  return (
    <AppDataContext.Provider
      value={{
        menus,
        logoUrl,
        isDataLoading,
        subdomain,
        headerBackgroundColor,
        headerMenuFontColor,
        customStyles,
        websiteHeaderCustomUrl,
        websiteFooterCustomUrl,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export default AppDataProvider;
