import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { API_URL } from '../../config';
import { useAppData } from '../component/AppDataContext';

function RowGalleryModal({ show, onHide, onSelectTemplate }) {
  const { subdomain } = useAppData();
  const [rowTemplates, setRowTemplates] = useState([]);

  useEffect(() => {
    if (!subdomain || !show) return;
    // Fetch row templates from your backend
    fetch(`${API_URL}/row-templates?account_key=${subdomain}`)
      .then((res) => res.json())
      .then((data) => {
        const templates = data.data || [];
        setRowTemplates(templates);
      })
      .catch((err) => console.error(err));
  }, [subdomain, show]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Choose a Saved Row Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rowTemplates.length === 0 ? (
          <p>No saved row templates found.</p>
        ) : (
          <div className="row-templates-grid">
            {rowTemplates.map((tpl) => (
              <div
                key={tpl.id}
                className="row-template-item"
                onClick={() => onSelectTemplate(tpl.row_data)}
                style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', cursor: 'pointer' }}
              >
                <h5>{tpl.name}</h5>
                {/* You could render a small preview here if you want */}
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RowGalleryModal;
