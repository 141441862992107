import React, { useState, useRef, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faEye,
    faClone,
    faCompass,
    faSpinner,
    faEllipsisVertical,
    faChevronRight,
    faCode,
    faBars
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MetaDataModal from './MetaDataModal'; // Import the MetaDataModal component

// Import your custom Hook for the data (websiteHeaderCustomUrl, websiteFooterCustomUrl)
import { useAppData } from '../component/AppDataContext'; // Adjust the import path as needed
import { useTour } from '../component/TourContext'; // Adjust path as needed

function BuilderHeader({
    pages = [],
    selectedPageId,
    onPageChange,
    onSavePage,
    loading,
    currentPageURL,
}) {
    const selectedPage = pages.find((page) => page.id === selectedPageId);
    const navigate = useNavigate();
    const { openTour } = useTour();

    // Get the IDs for the website header and footer from context
    const {
        websiteHeaderCustomUrl,
        websiteFooterCustomUrl,
    } = useAppData();

    // State for Page Selector Dropdown
    const [pageDropdownOpen, setPageDropdownOpen] = useState(false);
    const pageDropdownRef = useRef(null);

    // State for MetaData Modal
    const [showMetaDataModal, setShowMetaDataModal] = useState(false);
    const [metaDataContext, setMetaDataContext] = useState(''); // 'header', 'footer', 'customStyles', etc.

    // Toggle function for Page Selector Dropdown
    const togglePageDropdown = () => {
        setPageDropdownOpen((prev) => !prev);
    };

    // Handle clicks outside of the Page Selector Dropdown
    const handleClickOutside = (event) => {
        if (
            pageDropdownRef.current &&
            !pageDropdownRef.current.contains(event.target)
        ) {
            setPageDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handlers to open MetaData Modal (Mobile Header, Mobile Footer, Global Styles, etc.)
    const handleCustomizeHeader = () => {
        setMetaDataContext('header');
        setShowMetaDataModal(true);
    };

    const handleCustomizeFooter = () => {
        setMetaDataContext('footer');
        setShowMetaDataModal(true);
    };

    const handleCustomStyle = () => {
        setMetaDataContext('customStyles');
        setShowMetaDataModal(true);
    };

    // Handlers to navigate for Website Header and Website Footer
    const handleWebsiteHeader = () => {
        if (websiteHeaderCustomUrl) {
            // e.g., /header-footer-builder/4
            navigate(`/header-footer-builder/${websiteHeaderCustomUrl}`);
        } else {
            alert('No Website Header ID found in context.');
        }
    };

    const handleWebsiteFooter = () => {
        if (websiteFooterCustomUrl) {
            // e.g., /header-footer-builder/5
            navigate(`/header-footer-builder/${websiteFooterCustomUrl}`);
        } else {
            alert('No Website Footer ID found in context.');
        }
    };

    return (
        <nav className="navbar navbar-light">
            <div className="build-nav">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-3">
                        {/* Left Section: Title and Page Selector */}
                        <div className="d-flex align-items-center justify-content-between page-info">
                            <div className="page-link-name">
                                {selectedPage ? selectedPage.title : 'Select Page'}
                                <span>{currentPageURL}</span>
                            </div>
                            <div className="select-page" ref={pageDropdownRef}>
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    onClick={togglePageDropdown}
                                    style={{ cursor: 'pointer' }}
                                />
                                {pageDropdownOpen && (
                                    <div className="page-name-display">
                                        <ul>
                                            {pages.length > 0 ? (
                                                pages.map((page) => (
                                                    <li
                                                        key={page.id}
                                                        onClick={() => {
                                                            onPageChange(page.id);
                                                            setPageDropdownOpen(false);
                                                        }}
                                                        className={
                                                            page.id === selectedPageId
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                                                        {page.title}
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No pages available</li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-9 d-flex align-items-center justify-content-between">
                        {/* Center Section: Primary Actions */}
                        <div className="d-flex align-items-center build-nav-right">
                            <button className="btn me-2" onClick={openTour}>
                                <FontAwesomeIcon icon={faCompass} /> Tour Guide
                            </button>
                            <button
                                className="btn me-2 preview-site-btn"
                                onClick={() => {
                                    if (selectedPageId && selectedPage?.slug) {
                                        navigate(`/preview/${selectedPage.slug}/${selectedPage.id}`);
                                    } else {
                                        alert('No page selected to preview.');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} /> Preview Site
                            </button>
                            <Dropdown align="end" className="header-footer-dropdown">
                                <Dropdown.Toggle
                                    as="div"
                                    className="cust-head"
                                    style={{ cursor: 'pointer' }}
                                    id="dropdown-customize"
                                >
                                    Header/Footer
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="page-set-dropdown">
                                    {/* WEBSITE HEADER (navigate via ID) */}
                                    <Dropdown.Item onClick={handleWebsiteHeader}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Website Header
                                    </Dropdown.Item>
                                    {/* WEBSITE FOOTER (navigate via ID) */}
                                    <Dropdown.Item onClick={handleWebsiteFooter}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Website Footer
                                    </Dropdown.Item>

                                    {/* MOBILE HEADER: open MetaData Modal */}
                                    <Dropdown.Item onClick={handleCustomizeHeader}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Mobile Header
                                    </Dropdown.Item>
                                    
                                    {/* GLOBAL STYLESHEET: also opens MetaData Modal */}
                                    <Dropdown.Item onClick={handleCustomStyle}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Global Styleheet
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="d-flex align-items-center me-5 save-page-btn-wrap">
                            {/* Right Section: Customization Options */}
                            <div className="save-head">
                                <button
                                    className="btn btn-light"
                                    onClick={onSavePage}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} /> Saving...
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                        </>
                                    )}
                                </button>
                            </div>

                            

                            
                        </div>
                    </div>
                </div>
            </div>

            {/* MetaData Modal for mobile header/footer, global styles, etc. */}
            <MetaDataModal
                show={showMetaDataModal}
                handleClose={() => setShowMetaDataModal(false)}
                context={metaDataContext}
                selectedPageId={selectedPageId}
            />
        </nav>
    );
}

BuilderHeader.propTypes = {
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string, // if your page object has a slug
        })
    ),
    selectedPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPageChange: PropTypes.func.isRequired,
    onSavePage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    currentPageURL: PropTypes.string,
};

export default BuilderHeader;
