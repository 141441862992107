import React from 'react';
import { Carousel } from 'react-bootstrap';

const ClassicTemplate = ({ headline, description, testimonials, styles = {} }) => (
    <div className="testimonial-classic-wrap"  style={styles.container || {}}>
        {/*<h2 style={styles.headline || {}}>{headline}</h2>
        <p style={styles.description || {}}>{description}</p>*/}
        <Carousel indicators={true} interval={5000}>
            {testimonials.map((testimonial, idx) => (
                <Carousel.Item key={idx} className="testimonial-item-classic text-center">
                    {/* Image Section */}
                    <div className="text-center mb-3">
                        <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="rounded-circle img-fluid"
                            style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "15px",
                                objectFit: "cover",
                                ...styles.image,
                            }}
                        />
                    </div>
                    {/* Content Section */}
                    <p style={styles.message || {}}>"{testimonial.message}"</p>
                    <h5 style={styles.name || {}}>{testimonial.name}</h5>
                    <h6 style={styles.position || {}}>{testimonial.position}</h6>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default ClassicTemplate;
