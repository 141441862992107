import React, { useReducer, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './component/Loading'; // LoadingOverlay component
import { API_URL } from '../config';
import { componentTypes } from './config/componentTypes';
import { safeParseJSON } from './utils/safeParseJSON';
import { useAppData } from './component/AppDataContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Helmet } from 'react-helmet-async';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';

const initialState = { rows: [] };

function reducer(state, action) {
    switch (action.type) {
        case 'LOAD_PAGE_DATA':
            return {
                ...state,
                rows: action.payload.page_builder_rows.map(row => ({
                    id: row.id,
                    disabled: row.disabled,
                    styles: safeParseJSON(row.styles, {}), // Safely parse styles

                    sections: row.page_builder_sections.map(section => ({
                        id: section.id,
                        disabled: section.disabled,
                        styles: safeParseJSON(section.styles, {}), // Safely parse section styles
                        layout_key: section.layout_key,

                        columns: section.page_builder_columns.map(column => ({
                            class: column.class,
                            styles: safeParseJSON(column.styles, {}),
                            components: column.page_builder_components.map(component => ({
                                type: component.type,
                                props: safeParseJSON(component.props, {}), // Safely parse component props
                            })),

                            subsections: column.page_builder_subsections.map(subsection => ({
                                id: subsection.id,
                                disabled: subsection.disabled,
                                layout_key: subsection.layout_key,

                                subcolumns: subsection.page_builder_subcolumns.map(subcolumn => ({
                                    class: subcolumn.class,
                                    styles: safeParseJSON(subcolumn.styles, {}),
                                    subcomponents: subcolumn.page_builder_subcomponents.map(subcomponent => ({
                                        type: subcomponent.type,
                                        props: safeParseJSON(subcomponent.props, {}), // Safely parse subcomponent props
                                    })),
                                })),
                            })),
                        })),
                    })),
                })),
            };
        default:
            return state;
    }
}

const fetchPageData = async (pageId, subdomain) => {
    try {
        const response = await fetch(`${API_URL}/pages-builder/${pageId}?account_key=${subdomain}`);
        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

// Helper function to check if a row has any non-full-screen components
const rowHasNonFullScreenComponents = (row) => {
    // Iterate through sections
    for (const section of row.sections) {
        if (section.disabled === 1) continue;

        // Iterate through columns
        for (const column of section.columns) {
            // Check top-level components
            for (const comp of column.components) {
                if (!(comp.type === 'carousel' && comp.props.isFullScreen)) {
                    return true;
                }
            }

            // Iterate through subsections
            for (const subsection of column.subsections) {
                if (subsection.disabled === 1) continue;

                for (const subcolumn of subsection.subcolumns) {
                    for (const subcomp of subcolumn.subcomponents) {
                        if (!(subcomp.type === 'carousel' && subcomp.props.isFullScreen)) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
};

function PageBuilder({ pageId: propPageId }) {
    const { pageId: paramPageId } = useParams();
    const pageId = propPageId || paramPageId;
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);
    const { subdomain } = useAppData();
    const [currentPage, setCurrentPage] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (pageId) {
            setIsLoading(true);
            fetchPageData(pageId, subdomain)
                .then(pageData => {
                    setCurrentPage(pageData);
                    dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error loading page data:', error);
                    setIsLoading(false);
                });
        }
    }, [pageId, subdomain]);

    // Identify full-screen carousels
    const fullScreenCarousels = state.rows.flatMap(row =>
        row.sections.flatMap(section =>
            section.columns.flatMap(column =>
                column.components.filter(
                    comp => comp.type === 'carousel' && comp.props.isFullScreen
                )
            )
        )
    );

    // Filter out rows that do not have any non-full-screen components
    const filteredRows = state.rows.filter(row => rowHasNonFullScreenComponents(row));



    return (
        <div
            id={`custom-pages-${pageId}`}
            className={`custom-pages-${pageId}`}
        >
            {isLoading && <Loading />}

            {fullScreenCarousels.map((carouselComp, idx) => (
                <div key={`fullscreen-carousel-${idx}`} className="carousel-full-screen">
                    {React.createElement(componentTypes[carouselComp.type], {
                        ...carouselComp.props,
                    })}
                </div>
            ))}

            {/* Render filtered rows */}
            {filteredRows.map(row => (
                <Row key={row.id} row={row} />
            ))}
        </div>
    );
}

function Row({ row }) {
  const rowStyles = useResponsiveStyles(row.styles || {});
  const { overlayColor, overlayOpacity, ...restOfStyles } = rowStyles;

  const containerStyles = {
    position: 'relative', 
    ...restOfStyles,
  };

  return (
    <div className="row-wrap" style={containerStyles}>
      {overlayColor && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: overlayColor,
            // Convert your stored string to a float
            opacity: parseFloat(overlayOpacity) || 1, 
            pointerEvents: 'none',
          }}
        />
      )}

      {/* 5) Render sections beneath the overlay */}
      {row.sections.map((section) => (
        <Section 
            key={section.id} 
            section={section}    
            overlayColor={rowStyles.overlayColor}
            overlayOpacity={rowStyles.overlayOpacity}
        />
      ))}
    </div>
  );
}


// Nested Section Component within the same file
function Section({ section, overlayColor, overlayOpacity }) {
    const appliedStyles = useResponsiveStyles(section.styles || {});
    
    const containerStyles = {
       ...appliedStyles,
       ...(overlayColor ? { position: 'relative' } : {})
    };

    return (
        <div className="section-wrap" style={containerStyles}>
            <div className="container">
                <div className="row">
                    {section.columns.map((column, index) => (
                        <Column key={index} column={column} />
                    ))}
                </div>
            </div>
        </div>
    );
}

// Nested Column Component within the same file
function Column({ column }) {
    const appliedStyles = useResponsiveStyles(column.styles || {});

    return (
        <div className={`${column.class} p-3-custom`} style={appliedStyles}>
            {column.subsections.map(subsection => (
                <Subsection key={subsection.id} subsection={subsection} />
            ))}

            {column.components.map((comp, index) => {
                if (comp.type === 'carousel' && comp.props.isFullScreen) {
                    return null; // Skip full-screen carousels here
                }
                return (
                    <ComponentRenderer key={index} type={comp.type} props={comp.props} />
                );
            })}
        </div>
    );
}

// Nested Subsection Component within the same file
function Subsection({ subsection }) {
    if (subsection.disabled === 1) return null;

    return (
        <div className="row">
            {subsection.subcolumns.map(subcolumn => (
                <Subcolumn key={subcolumn.id} subcolumn={subcolumn} />
            ))}
        </div>
    );
}

// Nested Subcolumn Component within the same file
function Subcolumn({ subcolumn }) {
    const appliedStyles = useResponsiveStyles(subcolumn.styles || {});

    return (
        <div className={`${subcolumn.class} p-3-custom`} style={appliedStyles}>
            {subcolumn.subcomponents.map((subcomp, index) => (
                <ComponentRenderer key={index} type={subcomp.type} props={subcomp.props} />
            ))}
        </div>
    );
}

// ComponentRenderer to dynamically render components based on type
function ComponentRenderer({ type, props }) {
    const Component = componentTypes[type];
    if (!Component) {
        console.error(`Undefined component type: ${type}`);
        return null;
    }
    return <Component {...props} />;
}

export default PageBuilder;
