import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import MediaLibrary from '../component/MediaLibrary';
import { API_URL } from '../../config'; 
import { useAppData } from '../component/AppDataContext';
import ColorPicker from '../component/ColorPicker';
import ColorPickerModal from '../component/ColorPickerModal';
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import { oneDark } from '@codemirror/theme-one-dark';

const MetaDataModal = ({ show, handleClose, context, selectedPageId }) => {
  const [metaData, setMetaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { subdomain: accountKey } = useAppData(); 

  // State for ColorPickerModal (for general meta fields)
  const [showColorPickerModal, setShowColorPickerModal] = useState(false);
  const [currentColorField, setCurrentColorField] = useState(null);

  // **State for Website Logo (generative option)**
  const [useGenerativeLogo, setUseGenerativeLogo] = useState(false);
  const [generativeBrandName, setGenerativeBrandName] = useState('');
  const [generativeLogoColor, setGenerativeLogoColor] = useState('#000');
  const [logoFont, setLogoFont] = useState('Lobster');

  // When metaData or formValues update, check if website_logo is generative.
  useEffect(() => {
    // Look for the website_logo meta field
    metaData.forEach(meta => {
      if (meta.meta_identifier === 'website_logo') {
        const fieldName = `meta_value_${meta.id}`;
        const value = formValues[fieldName];
        if (value && value.includes('https://www.fiklu.com/dynamic-svg')) {
          try {
            const url = new URL(value);
            const brandname = url.searchParams.get('brandname') || '';
            const color = url.searchParams.get('color') || '#000';
            const font = url.searchParams.get('font') || 'Lobster';
            setGenerativeBrandName(brandname);
            setGenerativeLogoColor(color);
            setLogoFont(font);
            setUseGenerativeLogo(true);
          } catch (err) {
            console.error('Error parsing generative logo URL:', err);
          }
        }
      }
    });
  }, [metaData, formValues]);

  // Fetch metadata when modal opens
  useEffect(() => {
    if (show) fetchMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, context, selectedPageId]);

  const fetchMetaData = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        category: context, 
        account_key: accountKey,
      });
      const response = await fetch(`${API_URL}/meta-data/category?${params.toString()}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('No metadata found for the specified category.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }
      const data = await response.json();
      setMetaData(data.metaDataList);
      // Initialize form values from meta data list
      const initialValues = {};
      data.metaDataList.forEach((item) => {
        initialValues[`meta_value_${item.id}`] =
          Array.isArray(item.meta_value) ? item.meta_value : item.meta_value;
      });
      setFormValues(initialValues);
    } catch (error) {
      console.error('Error fetching metadata:', error);
      alert(error.message || 'Failed to fetch metadata.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;
    if (type === 'select-multiple') {
      const selectedOptions = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);
      setFormValues({ ...formValues, [name]: selectedOptions });
    } else if (type === 'checkbox') {
      setFormValues({ ...formValues, [name]: checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleImageSelect = (url, metaId) => {
    const fieldName = `meta_value_${metaId}`;
    setFormValues({ ...formValues, [fieldName]: url });
  };

  const openColorPickerModal = (fieldName) => {
    setCurrentColorField(fieldName);
    setShowColorPickerModal(true);
  };

  const closeColorPickerModal = () => {
    setShowColorPickerModal(false);
    setCurrentColorField(null);
  };

  const handleColorSelection = (newColor) => {
    if (currentColorField) {
      setFormValues({ ...formValues, [currentColorField]: newColor });
    }
    // Optionally close the modal automatically:
    // closeColorPickerModal();
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const params = new URLSearchParams({ account_key: accountKey });
      const payload = { page_id: selectedPageId, context, metaData: formValues };
      const response = await fetch(`${API_URL}/meta-data/update?${params.toString()}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const result = await response.json();
      if (result.success) {
        alert('Metadata updated successfully.');
        handleClose();
      } else {
        throw new Error(result.message || 'Failed to update metadata.');
      }
    } catch (error) {
      console.error('Error updating metadata:', error);
      alert(error.message || 'Failed to update metadata.');
    } finally {
      setSaving(false);
    }
  };

  // Render form fields based on meta type
  const renderFormField = (meta) => {
    const fieldName = `meta_value_${meta.id}`;
    const value = formValues[fieldName] || '';
    switch (meta.meta_type) {
      case 'text':
        return (
          <Form.Control
            type="text"
            name={fieldName}
            value={value}
            onChange={handleInputChange}
            placeholder={meta.placeholder || ''}
          />
        );
      case 'textarea':
        return (
          <Form.Control
            as="textarea"
            rows={3}
            name={fieldName}
            value={value}
            onChange={handleInputChange}
            placeholder={meta.placeholder || ''}
          />
        );
      case 'color':
        return (
          <div className="d-flex align-items-center">
            <ColorPicker
              color={value || '#ffffff'}
              onOpen={() => openColorPickerModal(fieldName)}
            />
            <Form.Control
              type="text"
              name={fieldName}
              value={value}
              onChange={handleInputChange}
              placeholder="#ffffff"
              className="ms-2"
            />
          </div>
        );
      case 'image':
 if (meta.meta_identifier === 'website_logo') {
  return (
    <div className="row">
      {/* Left Column: Logo Preview */}
      <div className="col-md-3 d-flex align-items-center justify-content-center">
        {value ? (
          <div className="mt-2">
            <Image
              src={value}
              alt="Logo Preview"
              thumbnail
              style={{ width: '100%', maxWidth: '200px', objectFit: 'cover' }}
            />
          </div>
        ) : (
          <div className="mt-2 text-center">
            <p>No Logo</p>
          </div>
        )}
      </div>
      
      {/* Right Column: Form Fields */}
      <div className="col-md-9">
        <Form.Group>
          <Form.Check
            type="radio"
            label="Upload Custom Image"
            name={`logoOption_${meta.id}`}
            id={`uploadLogoOption_${meta.id}`}
            checked={!useGenerativeLogo}
            onChange={() => setUseGenerativeLogo(false)}
          />
          <Form.Check
            type="radio"
            label="Use Generative Logo"
            name={`logoOption_${meta.id}`}
            id={`generativeLogoOption_${meta.id}`}
            checked={useGenerativeLogo}
            onChange={() => setUseGenerativeLogo(true)}
          />
        </Form.Group>
        {useGenerativeLogo ? (
          <>
            <Form.Group controlId={`generativeBrandName_${meta.id}`}>
              <Form.Label>Brand Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter brand name"
                value={generativeBrandName}
                onChange={(e) => setGenerativeBrandName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId={`generativeLogoColor_${meta.id}`}>
              <Form.Label>Logo Color</Form.Label>
              <ColorPicker
                field={`generativeLogoColor_${meta.id}`}
                color={generativeLogoColor}
                onOpen={() => setShowColorPickerModal(true)}
              />
              {showColorPickerModal && (
                <ColorPickerModal
                  show={showColorPickerModal}
                  handleClose={closeColorPickerModal}
                  color={generativeLogoColor}
                  handleColorChange={(newColor) => setGenerativeLogoColor(newColor)}
                />
              )}
            </Form.Group>
            <Form.Group controlId={`logoFont_${meta.id}`}>
              <Form.Label>Logo Design</Form.Label>
              <Form.Control
                as="select"
                value={logoFont}
                onChange={(e) => setLogoFont(e.target.value)}
              >
                <option value="Lobster">Lobster</option>
                <option value="Bricolage">Bricolage</option>
                <option value="Pirate">Pirate</option>
                <option value="Squada">Squada</option>
                <option value="Kavoon">Kavoon</option>
              </Form.Control>
            </Form.Group>
            <div className="">

              <Button
                variant="primary"
                onClick={() => {
                  const url = `https://www.fiklu.com/dynamic-svg?account_key=${accountKey}&brandname=${encodeURIComponent(
                    generativeBrandName
                  )}&color=${encodeURIComponent(generativeLogoColor)}&font=${encodeURIComponent(
                    logoFont
                  )}`;
                  setFormValues({ ...formValues, [fieldName]: url });
                }}
                className="chg-media-img"
              >
                Generate Logo
              </Button>


              <Button
                variant="danger"
                className="mt-2"
                onClick={() => setFormValues({ ...formValues, [fieldName]: '' })}
              >
                Remove Logo
              </Button>
            </div>
          </>
        ) : (
          <div>
            <MediaLibrary
              onSelectImage={(url) => handleImageSelect(url, meta.id)}
              multiple={false}
            />
            <Button
              variant="danger"
              className="mt-2"
              onClick={() => setFormValues({ ...formValues, [fieldName]: '' })}
            >
              Remove Logo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}  else {
  // For other image meta fields
  return (
    <div className="row">
      <div className="col-md-4">
        {value && (
          <div className="mt-2">
            <Image
              src={value}
              alt="Selected"
              thumbnail
              style={{ height: '100px', width: '100px', objectFit: 'cover' }}
            />
          </div>
        )}
      </div>
      <div className="col-md-8">
        <MediaLibrary
          onSelectImage={(url) => handleImageSelect(url, meta.id)}
          multiple={false}
        />
      </div>
    </div>
  );
}

      case 'stylesheet':
        return (
          <CodeMirror
            value={value}
            extensions={[css()]}
            theme={oneDark}
            basicSetup={{
              lineNumbers: true,
              foldGutter: true,
              highlightActiveLine: true,
            }}
            onChange={(value) => setFormValues({ ...formValues, [fieldName]: value })}
            height="300px"
          />
        );
      default:
        return (
          <Form.Control
            type="text"
            name={fieldName}
            value={value}
            onChange={handleInputChange}
          />
        );
    }
  };

  const getModalTitle = () => {
    switch (context) {
      case 'header':
        return 'Customize Header';
      case 'footer':
        return 'Customize Footer';
      case 'customStyles':
        return 'Custom Style Sheet';
      default:
        return 'Customize';
    }
  };

  if (!show) return null;

  return (
    <>
      <Modal className="model-styling" show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-content">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <Form>
                {metaData.map((meta) => (
                  <div className="icon-card-form carousel-wrap pb-2" key={meta.id}>
                    <Form.Group className="mb-3 single-img-wrap-e">
                      <Form.Label>
                        {meta.meta_key}
                        {meta.description && (
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={meta.description}
                            className="fas fa-info-circle ms-2"
                          ></i>
                        )}
                      </Form.Label>
                      {renderFormField(meta)}
                    </Form.Group>
                  </div>
                ))}
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} /> Close
          </Button>
          <Button variant="success" onClick={handleSubmit} disabled={saving || loading}>
            {saving ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} /> Save Changes
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Global ColorPickerModal for non-website_logo fields */}
      {currentColorField && (
        <ColorPickerModal
          show={showColorPickerModal}
          handleClose={closeColorPickerModal}
          color={formValues[currentColorField] || '#ffffff'}
          handleColorChange={handleColorSelection}
        />
      )}
    </>
  );
};

MetaDataModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  context: PropTypes.oneOf(['header', 'footer', 'customStyles']).isRequired,
  selectedPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default MetaDataModal;
