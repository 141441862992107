import TextComponent from '../component/pagebuilder/TextComponent';
import ImageComponent from '../component/pagebuilder/ImageComponent';
import BrandLogo from '../component/pagebuilder/BrandLogo';

import ButtonComponent from '../component/pagebuilder/ButtonComponent';
import HeadingComponent from '../component/pagebuilder/HeadingComponent';
import VideoComponent from '../component/pagebuilder/VideoComponent';
import GalleryComponent from '../component/pagebuilder/GalleryComponent';
import BlockquoteComponent from '../component/pagebuilder/BlockquoteComponent';
import AboutUsComponent from '../component/pagebuilder/AboutUsComponent';
import BootstrapCardComponent from '../component/pagebuilder/BootstrapCardComponent';
import ImageOverlayCard from '../component/pagebuilder/ImageOverlayCard';
import HeaderFooterCard from '../component/pagebuilder/HeaderFooterCard';
import HorizontalCard from '../component/pagebuilder/HorizontalCard';
import ListGroupCard from '../component/pagebuilder/ListGroupCard';
import DividerComponent from '../component/pagebuilder/DividerComponent';
import ListComponent from '../component/pagebuilder/ListComponent';
import AlertComponent from '../component/pagebuilder/AlertComponent';
import InputComponent from '../component/pagebuilder/InputComponent';
import TextareaComponent from '../component/pagebuilder/TextareaComponent';
import SelectComponent from '../component/pagebuilder/SelectComponent';
import CheckboxComponent from '../component/pagebuilder/CheckboxComponent';
import RadioComponent from '../component/pagebuilder/RadioComponent';
import AudioComponent from '../component/pagebuilder/AudioComponent';
import IframeComponent from '../component/pagebuilder/IframeComponent';
import TabsComponent from '../component/pagebuilder/TabsComponent';
import AccordionComponent from '../component/pagebuilder/AccordionComponent';
import CarouselComponent from '../component/pagebuilder/CarouselComponent';
import ModalComponent from '../component/pagebuilder/ModalComponent';
import SpacerComponent from '../component/pagebuilder/SpacerComponent';
import BadgeComponent from '../component/pagebuilder/BadgeComponent';
import PricingTableComponent from '../component/pagebuilder/PricingTableComponent';
import MapComponent from '../component/pagebuilder/MapComponent';
import FAQComponent from '../component/pagebuilder/FAQComponent';
import SocialMediaIconsComponent from '../component/pagebuilder/SocialMediaIconsComponent';
import CountdownTimerComponent from '../component/pagebuilder/CountdownTimerComponent';
import TimelineComponent from '../component/pagebuilder/TimelineComponent';
import LightboxGalleryComponent from '../component/pagebuilder/LightboxGalleryComponent';
import FeatureCardsComponent from '../component/pagebuilder/FeatureCardsComponent';
import TeamSectionComponent from '../component/pagebuilder/TeamSectionComponent';
import CategoryList from '../component/pagebuilder/CategoryList';
import ProductList from '../component/pagebuilder/ProductList';
import RichTextDisplay from '../component/pagebuilder/RichTextDisplay';
import IconCard from '../component/pagebuilder/IconCard';
import IconComponent from '../component/pagebuilder/IconComponent';

import TestimonialComponent from '../component/pagebuilder/TestimonialComponent';
import ProgressComponent from '../component/pagebuilder/ProgressComponent';
import Breadcrumb from '../component/pagebuilder/Breadcrumb';
import ContactFormComponent from '../component/pagebuilder/ContactFormComponent'; // Import the new component
import BlogList from '../component/pagebuilder/BlogList';
import Blogs from '../component/pagebuilder/Blogs';

import Menus from '../component/pagebuilder/Menus';


import BlogPostTitle from '../component/pagebuilder/BlogPostTitle';
import BlogPostBody from '../component/pagebuilder/BlogPostBody';
import BlogPostExcerpt from '../component/pagebuilder/BlogPostExcerpt';


export const componentTypes = {
    text: TextComponent,
    image: ImageComponent,
    brandLogo: BrandLogo,
    button: ButtonComponent,
    aboutUs: AboutUsComponent,
    cardComponent: BootstrapCardComponent,
    testimonialComponent: TestimonialComponent,
    imageOverlayCard: ImageOverlayCard,
    headerFooterCard: HeaderFooterCard,
    horizontalCard: HorizontalCard,
    iconCard: IconCard,
    iconComponent: IconComponent,
    listGroupCard: ListGroupCard,
    divider: DividerComponent,
    list: ListComponent,
    alert: AlertComponent,
    input: InputComponent,
    textarea: TextareaComponent,
    select: SelectComponent,
    checkbox: CheckboxComponent,
    radio: RadioComponent,
    audio: AudioComponent,
    iframe: IframeComponent,
    tabs: TabsComponent,
    accordion: AccordionComponent,
    carousel: CarouselComponent,
    modal: ModalComponent,
    spacer: SpacerComponent,
    badge: BadgeComponent,
    heading: HeadingComponent,
    video: VideoComponent,
    gallery: GalleryComponent,
    blockquote: BlockquoteComponent,
    pricingTable: PricingTableComponent,
    map: MapComponent,
    faq: FAQComponent,
    socialMediaIcons: SocialMediaIconsComponent,
    countdownTimer: CountdownTimerComponent,
    timeline: TimelineComponent,
    lightboxGallery: LightboxGalleryComponent,
    featureCards: FeatureCardsComponent,
    teamSection: TeamSectionComponent,
    categoryList: CategoryList,
    productList: ProductList,
    blogList: BlogList,
    blogs: Blogs,
    progress: ProgressComponent,
    breadcrumb: Breadcrumb,
    menus: Menus, 
    richText: (props) => <RichTextDisplay {...props} />,
    contactForm: ContactFormComponent, 
    blogPostTitle: BlogPostTitle,
    blogPostBody: BlogPostBody,
    blogPostExcerpt: BlogPostExcerpt,
};