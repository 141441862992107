import React from 'react';
import { Form, Button, Image, Row, Col } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const socialIconOptions = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'Snapchat', value: 'snapchat' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'Pinterest', value: 'pinterest' },
  { label: 'Reddit', value: 'reddit' },
];

const TeamSectionForm = ({ 
  formValues, 
  setFormValues, 
  handleAddJsonArrayItem, 
  handleRemoveJsonArrayItem 
}) => {
  // Helper to update a team member’s field
  const updateTeamMemberField = (fieldName, memberIndex, key, value) => {
    const updatedMembers = [...formValues[fieldName]];
    updatedMembers[memberIndex][key] = value;
    setFormValues({ ...formValues, [fieldName]: updatedMembers });
  };

  // Helper to update a social media entry for a team member
  const updateSocialMediaField = (memberIndex, socialIndex, key, value) => {
    const updatedMembers = [...formValues.teamMembers];
    if (!updatedMembers[memberIndex].socialMedia) {
      updatedMembers[memberIndex].socialMedia = [];
    }
    updatedMembers[memberIndex].socialMedia[socialIndex][key] = value;
    setFormValues({ ...formValues, teamMembers: updatedMembers });
  };

  // Add a new social media entry for a team member
  const handleAddSocialMedia = (memberIndex) => {
    const updatedMembers = [...formValues.teamMembers];
    if (!updatedMembers[memberIndex].socialMedia) {
      updatedMembers[memberIndex].socialMedia = [];
    }
    updatedMembers[memberIndex].socialMedia.push({ icon: 'facebook', url: '' });
    setFormValues({ ...formValues, teamMembers: updatedMembers });
  };

  // Remove a social media entry for a team member
  const handleRemoveSocialMedia = (memberIndex, socialIndex) => {
    const updatedMembers = [...formValues.teamMembers];
    updatedMembers[memberIndex].socialMedia = updatedMembers[memberIndex].socialMedia.filter(
      (item, idx) => idx !== socialIndex
    );
    setFormValues({ ...formValues, teamMembers: updatedMembers });
  };

  // Render the team members list including the new social media inputs
  const renderJsonArrayFields = (fieldName) => (
    <>
      {formValues[fieldName]?.map((member, index) => (
        <div key={index} className="carousel-wrap">
          <div className="row">
            {/* Media Library for Image */}
            <div className="col-md-3">
              <div className="media-img-wrap">
                {member.image ? (
                  <Image
                    src={member.image}
                    alt={member.name || 'Team Member'}
                    thumbnail
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: '#ddd',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    No Image
                  </div>
                )}

                <MediaLibrary
                  onSelectImage={(url) => {
                    updateTeamMemberField(fieldName, index, 'image', url);
                  }}
                />
              </div>
            </div>

            {/* Name and Position */}
            <div className="col-md-9">
              <div className="mb-3">
                <label className="form-label">Team member name</label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={member.name || ''}
                  onChange={(e) =>
                    updateTeamMemberField(fieldName, index, 'name', e.target.value)
                  }
                  className="mb-2"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Team member position</label>
                <Form.Control
                  type="text"
                  placeholder="Position"
                  value={member.position || ''}
                  onChange={(e) =>
                    updateTeamMemberField(fieldName, index, 'position', e.target.value)
                  }
                  className="mb-2"
                />
              </div>

              {/* Social Media Section */}
              <div className="mb-3">
                <label className="form-label">Social Media</label>
                {(member.socialMedia || []).map((social, socialIndex) => (
                  <Row key={socialIndex} className="mb-2">
                    <Col md={4}>
                      <Form.Select
                        value={social.icon}
                        onChange={(e) =>
                          updateSocialMediaField(index, socialIndex, 'icon', e.target.value)
                        }
                      >
                        {socialIconOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        placeholder="Enter URL"
                        value={social.url}
                        onChange={(e) =>
                          updateSocialMediaField(index, socialIndex, 'url', e.target.value)
                        }
                      />
                    </Col>
                    <Col md={2}>
                        <a href="#" onClick={() => handleRemoveSocialMedia(index, socialIndex)}>
                         <FontAwesomeIcon icon={faTrash} />
                        </a>
                    </Col>
                  </Row>
                ))}
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleAddSocialMedia(index)}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Social Media
                </Button>
              </div>
            </div>
          </div>
          <Button
            variant="danger"
            className="mt-2"
            onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
          >
            Remove Member
          </Button>
        </div>
      ))}
      <Button
        variant="primary"
        className="add-more-media"
        onClick={() =>
          handleAddJsonArrayItem(fieldName, { image: '', name: '', position: '', socialMedia: [] })
        }
      >
        <FontAwesomeIcon icon={faPlus} /> Add Member
      </Button>
    </>
  );

  return (
    <div>
      {/* You can add a headline input if needed */}
      {renderJsonArrayFields('teamMembers')}
    </div>
  );
};

export default TeamSectionForm;
