// src/pages/component/TourContext.js
import React, { createContext, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Swal from 'sweetalert2';

const TourContext = createContext();

export const TourProvider = ({ children }) => {

  const location = useLocation();


  // Check local storage flag and current route for initial tour state
  const [isTourOpen, setIsTourOpen] = useState(() => {
    const tourDisabled = localStorage.getItem('tourDisabled');
    // Automatically open tour only if user is on "page-builder" route and hasn't disabled it
    return location.pathname.includes('page-builder') && !tourDisabled;
  });




  const openTour = () => setIsTourOpen(true);

  const closeTour = () => {
    // First, close the tour
    setIsTourOpen(false);

    // Then prompt the user with SweetAlert2
    Swal.fire({
      title: "Don't show tour again?",
      text: "Would you like to disable the tour permanently?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Don't show again",
      cancelButtonText: "Yes, show me next time",
      zIndex: 2000, // Ensure the alert appears on top
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem('tourDisabled', 'true');
      } else {
        localStorage.removeItem('tourDisabled');
      }
    });
  };

  return (
    <TourContext.Provider value={{ isTourOpen, openTour, closeTour }}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
