import React, { useState, useEffect } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MediaLibrary from '../MediaLibrary';
import { useAppData } from '../AppDataContext';
import ColorPicker from '../ColorPicker';
import ColorPickerModal from '../ColorPickerModal';

const BrandLogoform = ({ formValues, setFormValues }) => {
  const { subdomain } = useAppData(); // subdomain used as the account_key
  const [showLibrary, setShowLibrary] = useState(false);
  const [useGenerativeLogo, setUseGenerativeLogo] = useState(false);
  const [generativeBrandName, setGenerativeBrandName] = useState('');
  const [generativeLogoColor, setGenerativeLogoColor] = useState('#333');
  const [logoFont, setLogoFont] = useState('Lobster'); // Default font
  const [showColorPickerModal, setShowColorPickerModal] = useState(false);

  // If the image URL is a generative logo, parse its parameters and update state.
  useEffect(() => {
    if (formValues.imgSrc && formValues.imgSrc.includes('https://www.fiklu.com/dynamic-svg')) {
      try {
        const url = new URL(formValues.imgSrc);
        const brandname = url.searchParams.get('brandname') || '';
        const color = url.searchParams.get('color') || '#333';
        const font = url.searchParams.get('font') || 'Lobster';
        setGenerativeBrandName(brandname);
        setGenerativeLogoColor(color);
        setLogoFont(font);
        setUseGenerativeLogo(true);
      } catch (err) {
        console.error('Error parsing generative logo URL:', err);
      }
    }
  }, [formValues.imgSrc]);

  const handleImageSelect = (url) => {
    setFormValues({ ...formValues, imgSrc: url });
    setShowLibrary(false);
  };

  const handleRemoveImage = () => {
    setFormValues({ ...formValues, imgSrc: '' });
  };

  const handleGenerateLogo = () => {
    const url = `https://www.fiklu.com/dynamic-svg?account_key=${subdomain}&brandname=${encodeURIComponent(
      generativeBrandName
    )}&color=${encodeURIComponent(generativeLogoColor)}&font=${encodeURIComponent(logoFont)}`;
    setFormValues({ ...formValues, imgSrc: url });
  };

  return (
    <div className="carousel-wrap pb-3">
      <div className="row">
        <div className="col-md-3">
          <div className="media-img-wrap">
            {formValues.imgSrc ? (
              <Image
                src={formValues.imgSrc}
                alt="Selected Image"
                thumbnail
                style={{ width: '400px', objectFit: 'cover' }}
              />
            ) : (
              <div
                style={{
                  width: '400px',
                  height: '400px',
                  backgroundColor: '#f0f0f0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                }}
              >
                No Image Selected
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 single-img-wrap-e">
          <Form>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Upload Custom Image"
                name="logoOption"
                id="uploadLogoOption"
                checked={!useGenerativeLogo}
                onChange={() => setUseGenerativeLogo(false)}
              />
              <Form.Check
                type="radio"
                label="Use Generative Logo"
                name="logoOption"
                id="generativeLogoOption"
                checked={useGenerativeLogo}
                onChange={() => setUseGenerativeLogo(true)}
              />
            </Form.Group>

            {useGenerativeLogo ? (
              <>
                <Form.Group controlId="generativeBrandName">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter brand name"
                    value={generativeBrandName}
                    onChange={(e) => setGenerativeBrandName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="generativeLogoColor">
                  <Form.Label>Logo Color</Form.Label>
                  {/* Use ColorPicker instead of a text input */}
                  <ColorPicker
                    field="generativeLogoColor"
                    color={generativeLogoColor}
                    onOpen={() => setShowColorPickerModal(true)}
                  />
                  {showColorPickerModal && (
                    <ColorPickerModal
                      show={showColorPickerModal}
                      handleClose={() => setShowColorPickerModal(false)}
                      color={generativeLogoColor}
                      handleColorChange={(newColor) => {
                        setGenerativeLogoColor(newColor);
                      }}
                    />
                  )}
                </Form.Group>
                <Form.Group controlId="logoFont">
                  <Form.Label>Logo Design</Form.Label>
                  <Form.Control
                    as="select"
                    value={logoFont}
                    onChange={(e) => setLogoFont(e.target.value)}
                  >
                    <option value="Lobster">Lobster</option>
                    <option value="Bricolage">Bricolage</option>
                    <option value="Pirate">Pirate</option>
                    <option value="Squada">Squada</option>
                    <option value="Kavoon">Kavoon</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" className="mt-2" onClick={handleGenerateLogo}>
                  Generate Logo
                </Button>
                <Button variant="danger" className="mt-2 ml-2" onClick={handleRemoveImage}>
                  Remove Logo
                </Button>
              </>
            ) : (
              <>
                <MediaLibrary
                  onSelectImage={handleImageSelect}
                  show={showLibrary}
                  setShow={setShowLibrary}
                />
                <Button variant="danger" className="mt-2" onClick={handleRemoveImage}>
                  Remove Image
                </Button>
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BrandLogoform;
