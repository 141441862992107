import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../../config';
import { useAppData } from '../AppDataContext';
import { useNavigate } from 'react-router-dom';

const ProductList = ({ header, selectedProducts }) => {
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { subdomain } = useAppData();

    const handleCardClick = (productId) => {
        navigate(`/products/${productId}`);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);

                // Extract IDs if selectedProducts is an array of objects
                const productIds = selectedProducts.map(product => product.value);

                const response = await fetch(
                    `${API_URL}/products?account_key=${subdomain}&product_ids=${productIds.join(',')}`
                );
                const data = await response.json();

                if (response.ok && data.success) {
                    setProducts(data.products);
                } else {
                    throw new Error(data.message || 'Failed to fetch products.');
                }
            } catch (err) {
                console.error('Error fetching products:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (selectedProducts.length > 0) {
            fetchProducts();
        } else {
            setProducts([]);
            setLoading(false);
        }
    }, [selectedProducts]);


    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="danger" className="text-center">
                {error}
            </Alert>
        );
    }

    if (products.length === 0) {
        return (
            <div className="text-center">
                <p>No products found.</p>
            </div>
        );
    }

    return (
        <div className="shop-by-category home-product">
            <h2>
                <span>{header}</span>
            </h2>
            <div className="product-inner-wrap">
                <Row xs={2} md={3} lg={5} className="g-4">
                    {products.map(product => (
                        <Col key={product.product_id} onClick={() => handleCardClick(product.product_id)} style={{ cursor: 'pointer' }}>
                            <Card className="h-100">
                                <div className="image-wrap">
                                    <Card.Text className="rating">
                                        4 <FontAwesomeIcon icon={faStar} />
                                    </Card.Text>
                                    <Card.Img
                                        variant="top"
                                        src={product.media_urls}
                                        alt={product.product_name}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title>{product.product_name}</Card.Title>
                                    <Card.Text className="product-description">
                                        {product.description}
                                    </Card.Text>
                                    <Card.Text className="price">
                                        <span className="product-discountedPrice">
                                            ${product.discounted_price}
                                        </span>
                                        <span
                                            className="product-strike"
                                            style={{ textDecoration: 'line-through' }}
                                        >
                                            ${product.price}
                                        </span>
                                        <span className="product-discountPercentage">
                                            (Save{' '}
                                            {Math.round(
                                                ((product.price - product.discounted_price) /
                                                    product.price) *
                                                    100
                                            )}
                                            %)
                                        </span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default ProductList;
