// src/components/TourGuide.js
import React from 'react';
import Tour from 'reactour';

const tourSteps = [
  {
    selector: '.page-info',
    content: 'Welcome to the Site Pages area. Here you can view, select, and manage all your site pages. This section lets you quickly navigate between existing pages or even add new ones as your website grows.',
  },
  {
    selector: '.preview-site-btn',
    content: 'This is the Preview Site button. Clicking it opens a real-time preview of your site, so you can verify the layout, design, and functionality of your pages before publishing any changes.',
  },
  {
    selector: '.header-footer-dropdown',
    content: 'Access a variety of customization options through the Header/Footer dropdown menu. Use it to edit elements such as navigation links, branding, and other settings that appear consistently across your site’s header and footer.',
  },
  {
    selector: '.components-list',
    content: 'This sidebar houses a collection of draggable components. From text blocks and images to interactive widgets, you can easily drag and drop these elements onto your page to build and enhance your layout.',
  },
  {
    selector: '.toggle-sidebar-btn',
    content: 'Use this button to toggle the sidebar’s visibility. Whether you need a larger workspace or wish to focus solely on the design, this option lets you quickly hide or reveal the sidebar as needed.',
  },
  {
    selector: '.add-row-btn',
    content: 'Click here to add a new row section to your page. This feature helps you structure your content by creating distinct rows where you can place various components and elements to organize your layout better.',
  },
  {
    selector: '.save-head',
    content: 'After making any adjustments, be sure to click the Save button. This step is crucial for preserving all your changes and ensuring your updated design is applied to your live site.',
  },
];


const TourGuide = ({ isOpen, onClose }) => (
  <Tour
    steps={tourSteps}
    isOpen={isOpen}
    onRequestClose={onClose}
    rounded={5}
    accentColor="#5cb7b7"
  />
);

export default TourGuide;
