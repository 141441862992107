import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';

function BrandLogo({ imgSrc, styles = {}}) {
    
    // Apply responsive styles using the custom hook
    const appliedStyles = useResponsiveStyles(styles);
    
    // Destructure the link from the applied styles if it exists
    const { link, ...otherStyles } = appliedStyles?.style || {};
    
    // Define the container styles, including the background image
    const containerStyles = {
        backgroundImage: `url(${imgSrc})`,
        backgroundSize: 'contain',       // Adjusts the size of the background image
        backgroundRepeat: 'no-repeat',   // Prevents the image from repeating
        backgroundPosition: 'center',    // Centers the image within the container
        width: '200px',                    // Ensures the container takes full width (adjust as needed)
        height: '200px',                   // Ensures the container takes full height (adjust as needed)
        ...otherStyles,                   // Merge any additional styles from the hook
    };
    
    // Create the content element with the background image
    const content = (
        <div className="logo-wrap">
            <div 
                style={containerStyles}
                aria-label="Brand Logo"           // Accessibility: provides a label for screen readers
            ></div>
        </div>
    );

    // If a link is provided, wrap the content in an anchor tag
    return link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
    ) : (
        content
    );
}

export default BrandLogo;
