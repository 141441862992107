import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook, 
  faTwitter, 
  faInstagram, 
  faLinkedin, 
  faSnapchat, 
  faYoutube, 
  faPinterest, 
  faReddit 
} from '@fortawesome/free-brands-svg-icons';

const iconMapping = {
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
  snapchat: faSnapchat,
  youtube: faYoutube,
  pinterest: faPinterest,
  reddit: faReddit,
};

const ModernTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
  <div className="team-section-modern" style={styles.container}>
    {teamMembers.map((member, idx) => (
      <div
        key={member.id || idx} // Preferably use a unique ID, fallback to index
        className="team-member-modern text-center"
      >
        <div
          className="image-circle mx-auto mb-3"
          style={{
            backgroundImage: `url(${member.image})`,
            ...styles.image,
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
        <h5 style={styles.name}>{member.name}</h5>
        <p style={styles.position}>{member.position}</p>
        <div className="social-media-icons d-flex justify-content-center">
          {member.socialMedia && member.socialMedia.length > 0
            ? member.socialMedia.map((social, index) => {
                const iconToUse = iconMapping[social.icon];
                return (
                  <a
                    key={index}
                    href={social.url}
                    className="icon-link"
                    style={styles.icon}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={iconToUse} />
                  </a>
                );
              })
            : null}
        </div>
      </div>
    ))}
  </div>
);

export default ModernTeamTemplate;
