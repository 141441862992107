import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook, 
  faTwitter, 
  faInstagram, 
  faLinkedin, 
  faSnapchat, 
  faYoutube, 
  faPinterest, 
  faReddit 
} from '@fortawesome/free-brands-svg-icons';

const iconMapping = {
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
  snapchat: faSnapchat,
  youtube: faYoutube,
  pinterest: faPinterest,
  reddit: faReddit,
};

const SplitTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
  <div className="team-section-split" style={styles.container}>
    <div className="row g-4">
      {teamMembers.map((member, idx) => (
        <div key={idx} className="col-md-4 d-flex align-items-center split-content-wrap">
          <div
            className="team-image me-4"
            style={{
              backgroundImage: `url(${member.image})`,
              ...styles.image,
              width: '150px',
              height: '150px',
              borderRadius: '10px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="team-info">
            <h5 style={styles.name}>{member.name}</h5>
            <p style={styles.position}>{member.position}</p>
            <div className="social-media-icons">
              {member.socialMedia && member.socialMedia.length > 0
                ? member.socialMedia.map((social, index) => {
                    const iconToUse = iconMapping[social.icon];
                    return (
                      <a
                        key={index}
                        href={social.url}
                        className="icon-link"
                        style={styles.icon}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={iconToUse} />
                      </a>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default SplitTeamTemplate;
