// src/componentForms/ContactFormForm.js

import React, { useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const ContactFormForm = ({ formValues, setFormValues }) => {
  // Generate a unique default form name only once
  const defaultFormName = useRef(`contactform_${Math.floor(Math.random() * 10000)}`);

  // On mount, initialize formName, buttonText, and custom messages if they are undefined
  useEffect(() => {
    if (formValues.formName === undefined) {
      setFormValues(prev => ({ ...prev, formName: defaultFormName.current }));
    }
    if (formValues.buttonText === undefined) {
      setFormValues(prev => ({ ...prev, buttonText: 'Submit' }));
    }
    if (formValues.validationMessages === undefined) {
      setFormValues(prev => ({
        ...prev,
        validationMessages: {
          required: '$field is required.',
          email: '$field must be a valid email address.',
          number: '$field must be a valid number.'
        }
      }));
    }
    if (formValues.successMessage === undefined) {
      setFormValues(prev => ({ ...prev, successMessage: 'Form submitted successfully!' }));
    }
    // We intentionally run this effect only once on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...(formValues.formFields || [])];
    updatedFields[index][key] = value;
    setFormValues({ ...formValues, formFields: updatedFields });
  };

  const handleAddField = () => {
    const newField = {
      type: 'text',
      label: 'New Field',
      name: `field_${Date.now()}`,
      placeholder: '',
      required: false
    };
    setFormValues({ ...formValues, formFields: [...(formValues.formFields || []), newField] });
  };

  const handleRemoveField = (index) => {
    const updatedFields = (formValues.formFields || []).filter((_, i) => i !== index);
    setFormValues({ ...formValues, formFields: updatedFields });
  };

  return (
    <Form>
      <Form.Group className="mb-4 carousel-wrap pb-3">
        <Form.Label>Form Name</Form.Label>
        <Form.Control
          type="text"
          value={formValues.formName !== undefined ? formValues.formName : defaultFormName.current}
          onChange={(e) => setFormValues({ ...formValues, formName: e.target.value })}
          placeholder="Enter Form Name"
        />
      </Form.Group>

      <Form.Group className="mb-4 carousel-wrap pb-3">
        <Form.Label>Button Text</Form.Label>
        <Form.Control
          type="text"
          value={formValues.buttonText !== undefined ? formValues.buttonText : 'Submit'}
          onChange={(e) => setFormValues({ ...formValues, buttonText: e.target.value })}
          placeholder="Enter button text"
        />
      </Form.Group>

      {(formValues.formFields || []).map((field, index) => (
        <div key={index} className="mb-4 carousel-wrap">
          <div className="row">
            <Form.Group className="mb-2 col-md-6">
              <Form.Label>Field Type</Form.Label>
              <Form.Select
                value={field.type}
                onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
              >
                <option value="text">Text</option>
                <option value="email">Email</option>
                <option value="textarea">Textarea</option>
                <option value="number">Number</option>
                <option value="password">Password</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2 col-md-6">
              <Form.Label>Label</Form.Label>
              <Form.Control
                type="text"
                value={field.label}
                onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2 col-md-6">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={field.name}
                onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2 col-md-6">
              <Form.Label>Placeholder</Form.Label>
              <Form.Control
                type="text"
                value={field.placeholder}
                onChange={(e) => handleFieldChange(index, 'placeholder', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2 col-md-6">
              <Form.Check
                type="checkbox"
                label="Required"
                checked={field.required}
                onChange={(e) => handleFieldChange(index, 'required', e.target.checked)}
              />
            </Form.Group>
          </div>
          <Button variant="danger" onClick={() => handleRemoveField(index)} className="mt-2">
            <FontAwesomeIcon icon={faTrash} /> Remove Field
          </Button>
        </div>
      ))}

      <Button variant="primary" className="add-more-media" onClick={handleAddField}>
        <FontAwesomeIcon icon={faPlus} /> Add Field
      </Button>

      {/* Custom Validation Messages */}
      <div className="mb-4 carousel-wrap">
        <div className="row">
          <Form.Group className="mb-2 col-md-12">
            <Form.Label>Required Field Message</Form.Label>
            <Form.Control
              type="text"
              value={formValues.validationMessages?.required || '$field is required.'}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  validationMessages: {
                    ...formValues.validationMessages,
                    required: e.target.value
                  }
                })
              }
              placeholder="Enter custom required field message (use $field for field name)"
            />
          </Form.Group>

          <Form.Group className="mb-2 col-md-12">
            <Form.Label>Email Field Message</Form.Label>
            <Form.Control
              type="text"
              value={formValues.validationMessages?.email || '$field must be a valid email address.'}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  validationMessages: {
                    ...formValues.validationMessages,
                    email: e.target.value
                  }
                })
              }
              placeholder="Enter custom email field message (use $field for field name)"
            />
          </Form.Group>

          <Form.Group className="mb-2 col-md-12">
            <Form.Label>Number Field Message</Form.Label>
            <Form.Control
              type="text"
              value={formValues.validationMessages?.number || '$field must be a valid number.'}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  validationMessages: {
                    ...formValues.validationMessages,
                    number: e.target.value
                  }
                })
              }
              placeholder="Enter custom number field message (use $field for field name)"
            />
          </Form.Group>

          {/* Custom Success Message */}
          <Form.Group className="mb-2 col-md-12">
            <Form.Label>Success Message</Form.Label>
            <Form.Control
              type="text"
              value={formValues.successMessage || 'Form submitted successfully!'}
              onChange={(e) =>
                setFormValues({ ...formValues, successMessage: e.target.value })
              }
              placeholder="Enter custom success message"
            />
          </Form.Group>
        </div>
      </div>
    </Form>
  );
};

export default ContactFormForm;
