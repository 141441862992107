import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const exampleProps = {
    text: { content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    image: { imgSrc: 'https://placehold.co/100x100' },
    brandLogo: { imgSrc: 'https://placehold.co/100x100' },
    button: { text: 'Click Me' },
    heading: { text: 'Lorem Ipsum Heading', level: '1' },
    video: { src: 'https://www.youtube.com/watch?v=LXb3EKWsInQ' },
    blockquote: { quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', author: 'Author Name' },
    cardComponent: { title: 'Card Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    imageOverlayCard: { title: 'Overlay Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Overlay Body Text' },
    headerFooterCard: { header: 'Card Header', footer: 'Card Footer', title: 'Card Title', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    horizontalCard: { title: 'Horizontal Card Title', imageUrl: 'https://via.placeholder.com/300', bodyText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    listGroupCard: {
        title: 'List Group Title',
        items: [
            { text: 'Lorem ipsum dolor sit amet', icon: 'faCheckCircle' },
            { text: 'Consectetur adipiscing elit', icon: 'faStar' },
            { text: 'Sed do eiusmod tempor incididunt', icon: 'faAngleRight' },
        ],
    },
    divider: {},
    list: { items: ['Lorem ipsum dolor sit amet', 'Consectetur adipiscing elit', 'Sed do eiusmod tempor incididunt'] },
    alert: { type: 'warning', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    input: { placeholder: 'Enter text', value: '' },
    textarea: { placeholder: 'Enter multi-line text', value: '' },
    select: { options: [{ value: '1', label: 'Option 1' }, { value: '2', label: 'Option 2' }] },
    checkbox: { label: 'Check me', checked: false },
    radio: { name: 'exampleRadio', options: [{ value: '1', label: 'Option 1' }, { value: '2', label: 'Option 2' }] },
    audio: { src: 'https://www.w3schools.com/html/horse.mp3' },
    iframe: { src: 'https://www.example.com', height: '300', width: '100%' },
    tabs: { tabs: [{ key: 'tab1', title: 'Tab 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { key: 'tab2', title: 'Tab 2', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    accordion: { items: [{ title: 'Accordion Item 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { title: 'Accordion Item 2', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    carousel: { items: [{ src: 'https://via.placeholder.com/800x400', alt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { src: 'https://via.placeholder.com/800x400', alt: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', caption: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    modal: { title: 'Modal Title', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', buttonLabel: 'Open Modal' },
    spacer: { height: '20px' },
    badge: { text: 'New', variant: 'text-bg-secondary' },
    gallery: {
        "images": [
            {
                "src": "https://via.placeholder.com/150",
                "alt": null,
                "caption": null
            },
            {
                "src": "https://via.placeholder.com/200",
                "alt": null,
                "caption": null
            },
            {
                "src": "https://via.placeholder.com/250",
                "alt": null,
                "caption": null
            }
        ]
    },
    pricingTable: {
        plans: [
            {
                name: 'Basic',
                price: '10',
                features: [
                    'Access to basic features',
                    'Email support',
                    'Single user',
                ],
            },
            {
                name: 'Pro',
                price: '20',
                features: [
                    'All Basic features',
                    'Priority email support',
                    'Up to 5 users',
                    'Advanced analytics',
                ],
            },
            {
                name: 'Enterprise',
                price: '30',
                features: [
                    'All Pro features',
                    'Dedicated support',
                    'Unlimited users',
                    'Custom integrations',
                ],
            },
        ],
    },
    map: { location: { lat: 40.748817, lng: -73.985428 }, zoom: 12 },
    socialMediaIcons: {
        links: [
            { name: 'facebook', url: 'https://facebook.com', icon: faFacebook },
            { name: 'twitter', url: 'https://twitter.com', icon: faTwitter },
            { name: 'instagram', url: 'https://instagram.com', icon: faInstagram },
            { name: 'linkedin', url: 'https://linkedin.com', icon: faLinkedin }
        ]
    },
    progress: {
        items: [
                { title: 'Task 1', percentage: 50 },
                { title: 'Task 2', percentage: 75 },
                { title: 'Task 3', percentage: 90 },
            ]
    },
    countdownTimer: { targetDate: '2024-12-31T00:00:00' },
    timeline: { events: [{ title: 'Event 1', date: '2024-01-01', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }, { title: 'Event 2', date: '2024-06-01', description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }] },
    lightboxGallery: { images: [{ src: 'https://via.placeholder.com/600', thumbnail: 'https://via.placeholder.com/150', caption: 'Image 1 - Lorem ipsum dolor sit amet' }, { src: 'https://via.placeholder.com/600', thumbnail: 'https://via.placeholder.com/150', caption: 'Image 2 - Consectetur adipiscing elit' }] },
    featureCards: {
        "headline": "Our Features",
        "description": "Explore the amazing features that we offer to enhance your experience.",
        "features": [
            {
                "image": "https://via.placeholder.com/200",
                "title": "Feature 1",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                "image": "https://via.placeholder.com/200",
                "title": "Feature 2",
                "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
        ]
    },
    teamSection: {
        headline: "Our Team",
        description: "Meet our talented team, dedicated to driving innovation and excellence.",
        teamMembers: [
            {
                image: "https://via.placeholder.com/200",
                name: "John Doe",
                position: "CEO",
            },
            {
                image: "https://via.placeholder.com/200",
                name: "Jane Smith",
                position: "CTO",
            },
            {
                image: "https://via.placeholder.com/200",
                name: "Emily Johnson",
                position: "CFO",
            },
        ],
    },
    richText: { content: '<p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>.</p>' },
    categoryList: { location: { lat: 40.748817, lng: -73.985428 }, zoom: 12 },
    productList: { productListingHeader: "Hot Products", selectedProducts: [] },
    menus: { productListingHeader: "Menus", selectedMenus: [] },
    blogListing: {
        header: 'Latest News & Updates',
        selectedBlog: [],
        selectedPosts: [],
    },
    iconCard: { icon: 'faStar', title: 'Icon Card Title', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    faq: {
        title: 'Frequently Asked Questions',
        faqs: [
            { question: 'What is your return policy?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
            { question: 'Do you offer technical support?', answer: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }
        ]
    },
    testimonial: {
        headline: "What Our Customers Say",
        description: "Real testimonials from our happy customers.",
        testimonials: [
            {
                image: "https://via.placeholder.com/150",
                name: "Alice Johnson",
                position: "Marketing Specialist",
                message: "Astroyami's personalized insights helped me make better decisions for my career. Highly recommend it!",
            },
            {
                image: "https://via.placeholder.com/150",
                name: "Robert Brown",
                position: "Entrepreneur",
                message: "The experience with the app has been outstanding. The suggestions were spot on and incredibly insightful.",
            },
            {
                image: "https://via.placeholder.com/150",
                name: "Sophia Davis",
                position: "Freelance Designer",
                message: "I love how intuitive and accurate the platform is. It's like having my personal guide at all times.",
            },
        ],
    },
    breadcrumb: {
        items: [
          { text: 'Home', link: '/' },
          { text: 'Products', link: '/products' },
          { text: 'Shoes', link: '/products/shoes' },
        ],
        separator: '>',
        template: 'default', // Change to 'modern' or 'custom'
    },
    contactForm: {
        formFields: [
            { type: 'text', label: 'Name', name: 'name', placeholder: 'Enter your name', required: true },
            { type: 'email', label: 'Email', name: 'email', placeholder: 'Enter your email', required: true },
            { type: 'textarea', label: 'Message', name: 'message', placeholder: 'Enter your message', required: true },
        ],
    }
};
