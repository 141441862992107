import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const SocialMediaIconsForm = ({ formValues, setFormValues, handleAddJsonArrayItem, handleRemoveJsonArrayItem }) => {
    // Expanded dropdown options for icons
    const iconOptions = [
        { value: '', label: 'Select an Icon' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'twitter', label: 'Twitter' },
        { value: 'instagram', label: 'Instagram' },
        { value: 'linkedin', label: 'LinkedIn' },
        { value: 'youtube', label: 'YouTube' },
        { value: 'pinterest', label: 'Pinterest' },
        { value: 'snapchat', label: 'Snapchat' },
        { value: 'reddit', label: 'Reddit' },
    ];

    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3 carousel-wrap">
                    {/* Dropdown for selecting an icon */}
                    <Form.Group className="mb-3">
                        <Form.Label>Icon:</Form.Label>
                        <Form.Select
                            value={item.name}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].name = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        >
                            {iconOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {/* Input for URL */}
                    <Form.Group className="mb-3">
                        <Form.Label>URL:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter URL"
                            value={item.url}
                            onChange={(e) => {
                                const updatedItems = [...formValues[fieldName]];
                                updatedItems[index].url = e.target.value;
                                setFormValues({ ...formValues, [fieldName]: updatedItems });
                            }}
                        />
                    </Form.Group>
                    <Button
                        variant="danger"
                        className="mt-2"
                        onClick={() => handleRemoveJsonArrayItem(fieldName, index)}
                    >
                        Remove
                    </Button>
                </div>
            ))}
            <Button
                variant="primary"
                onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}
                className="add-more-media"
            >
                <FontAwesomeIcon icon={faPlus} /> Add More
            </Button>
        </>
    );

    return <>{renderJsonArrayFields('links', { name: '', url: '' })}</>;
};

export default SocialMediaIconsForm;
