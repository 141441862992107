import React from 'react';

const CustomBreadcrumb = ({ items, separator = '>', styles = {} }) => (
    <nav className="breadcrumb-container custom-breadcrumb" style={styles.container}>
        {items.map((item, index) => (
            <span key={index} className="breadcrumb-item">
                <a href={item.link} style={styles.link}>
                    {' '}
                    {item.text}
                </a>
                
            </span>
        ))}
    </nav>
);

export default CustomBreadcrumb;
