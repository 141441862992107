// RichTextEditor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange }) => {
  // State to control whether we are in code view or visual (WYSIWYG) mode
  const [isCodeView, setIsCodeView] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
      [{ align: [] }],
      ['code-block'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'align',
    'color', 'background',
    'code-block'
  ];

  // Toggle between code view and visual view
  const toggleView = () => {
    setIsCodeView((prev) => !prev);
  };

  // Handle changes in code view using a simple textarea
  const handleCodeChange = (e) => {
    // Since the content is HTML, we call onChange with the new value
    onChange(e.target.value);
  };

  return (
    <div>
      <button onClick={toggleView} style={{ marginBottom: '10px' }}>
        {isCodeView ? 'Switch to Visual Editor' : 'Switch to Code Editor'}
      </button>
      {isCodeView ? (
        <textarea
          value={value}
          onChange={handleCodeChange}
          style={{ width: '100%', height: '300px', fontFamily: 'monospace', padding: '10px' }}
        />
      ) : (
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
        />
      )}
    </div>
  );
};

export default RichTextEditor;
