import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useResponsiveStyles } from './useResponsiveStyles';

const PricingTableComponent = ({ plans = [], template = 'Classic', styles = {} }) => {
  // Converts style config + user overrides into inline style objects
  const appliedStyles = useResponsiveStyles(styles);

  // Consolidate all style references in one place for easy usage
  const allStyles = {
    container: appliedStyles.container,
    iconContainer: appliedStyles.iconContainer,
    planWrapper: appliedStyles.planWrapper,
    card: appliedStyles.card,
    header: appliedStyles.header,
    planName: appliedStyles.planName,
    price: appliedStyles.price,
    features: appliedStyles.features,
    featureItem: appliedStyles.featureItem,
    icon: appliedStyles.icon,
    footer: appliedStyles.footer,
    button: appliedStyles.button,
  };

  // Helper to render each feature <li> with an icon
  const renderFeatures = (features) =>
    features.map((feature, featureIdx) => (
      <li key={featureIdx} style={allStyles.featureItem}>
        <div className="price-list-icon" style={allStyles.iconContainer}><FontAwesomeIcon icon={faCheck} style={allStyles.icon} /></div> {feature}
      </li>
    ));

  switch (template) {
    // -----------------------------------------------------------
    // CLASSIC TEMPLATE
    // -----------------------------------------------------------
    case 'Classic':
      return (
        <div className="row pricing-classic-wrap" style={allStyles.container}>
          {plans.map((plan, idx) => (
            <div key={idx} className="col-md-4" style={allStyles.planWrapper}>
              <div className="card classic-card mb-4" style={allStyles.card}>

                {/* Price at the top */}
                <div className="pricing-card-price" style={allStyles.price}>
                  ${plan.price} <small></small>
                </div>

                {/* Card Header */}
                <div className="card-header" style={allStyles.header}>
                  <h4 className="my-0 font-weight-normal" style={allStyles.planName}>
                    {plan.name}
                  </h4>
                </div>

                {/* Card Body with Features */}
                <div className="card-body">
                  <ul className="list-unstyled" style={allStyles.features}>
                    {renderFeatures(plan.features)}
                  </ul>
                </div>

                {/* Card Footer with Button */}
                <div className="card-footer classic-card-footer" style={allStyles.footer}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={allStyles.button}
                  >
                    {plan.buttonText || 'Sign up'}
                  </button>
                </div>

              </div>
            </div>
          ))}
        </div>
      );

    // -----------------------------------------------------------
    // MODERN TEMPLATE
    // -----------------------------------------------------------
    case 'Modern':
      return (
        <div className="row pricing-modern-wrap" style={allStyles.container}>
          {plans.map((plan, idx) => (
            <div key={idx} className="col-md-4 p-0" style={allStyles.planWrapper}>
              <div className="card modern-card mb-4" style={allStyles.card}>

                {/* Card Header */}
                <div className="card-header" style={allStyles.header}>
                  <h4 className="card-title" style={allStyles.planName}>
                    {plan.name}
                  </h4>
                  <div className="pricing-card-price" style={allStyles.price}>
                    ${plan.price} <small></small>
                  </div>
                </div>

                {/* Card Body with Features */}
                <div className="card-body">
                  <ul className="card-features list-unstyled" style={allStyles.features}>
                    {renderFeatures(plan.features)}
                  </ul>
                </div>

                {/* Card Footer with Button */}
                <div className="card-footer modern-card-footer" style={allStyles.footer}>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={allStyles.button}
                  >
                    {plan.buttonText || 'Get Started'}
                  </button>
                </div>

              </div>
            </div>
          ))}
        </div>
      );

    // -----------------------------------------------------------
    // DEFAULT TEMPLATE
    // -----------------------------------------------------------
    default:
      return (
        <div className="row pricing-default-wrap" style={allStyles.container}>
          {plans.map((plan, idx) => (
            <div key={idx} className="col-md-4" style={allStyles.planWrapper}>
              <div className="card default-card mb-4" style={allStyles.card}>

                {/* Card Header (plan name) */}
                <div className="card-header" style={allStyles.header}>
                  <span style={allStyles.planName}>{plan.name}</span>
                </div>

                {/* Card Body with Price & Features */}
                <div className="card-body">
                  <h3 style={allStyles.price}>
                    <span>
                      ${plan.price} <small></small>
                    </span>
                  </h3>
                  <ul className="list-unstyled" style={allStyles.features}>
                    {renderFeatures(plan.features)}
                  </ul>
                </div>

                {/* Card Footer with Button */}
                <div className="card-footer default-card-footer" style={allStyles.footer}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={allStyles.button}
                  >
                    {plan.buttonText || 'Select'}
                  </button>
                </div>

              </div>
            </div>
          ))}
        </div>
      );
  }
};

export default PricingTableComponent;
