// LinkBuilder.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Tab, Form, ListGroup, Spinner } from 'react-bootstrap';
import { useAppData } from './AppDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

const LinkBuilder = ({ show, onClose, onSelectLink }) => {
    const { subdomain } = useAppData();
    const API_URL = process.env.REACT_APP_API_URL; // Ensure this variable is set

    const [activeTab, setActiveTab] = useState('external');
    const [externalUrl, setExternalUrl] = useState('');

    const [pages, setPages] = useState([]);
    const [loadingPages, setLoadingPages] = useState(false);

    const [blogs, setBlogs] = useState([]);
    const [loadingBlogs, setLoadingBlogs] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(false);

    useEffect(() => {
        if (activeTab === 'pages') fetchPages();
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'blog') fetchBlogs();
    }, [activeTab]);

    const fetchPages = async () => {
        setLoadingPages(true);
        try {
            const res = await fetch(`${API_URL}/pages/list/${encodeURIComponent(subdomain)}`);
            if (!res.ok) {
                throw new Error('Error fetching pages');
            }
            const data = await res.json();
            setPages(data.pages);
        } catch (error) {
        console.error('Error fetching pages', error);
    } finally {
        setLoadingPages(false);
    }
};

const fetchBlogs = async () => {
    setLoadingBlogs(true);
    try {
        const res = await fetch(`${API_URL}/blogs?account_key=${encodeURIComponent(subdomain)}`);
        if (!res.ok) {
            throw new Error('Error fetching blogs');
        }
        const data = await res.json();
        setBlogs(data.blogs);
    } catch (error) {
        console.error('Error fetching blogs', error);
    } finally {
        setLoadingBlogs(false);
    }
};

const fetchPosts = async (blogId) => {
    setLoadingPosts(true);
    try {
        const res = await fetch(`${API_URL}/blog/${blogId}?account_key=${encodeURIComponent(subdomain)}`);
        if (!res.ok) {
            throw new Error('Error fetching posts');
        }
        const data = await res.json();
        setPosts(data.posts || []);
    } catch (error) {
        console.error('Error fetching posts', error);
    } finally {
        setLoadingPosts(false);
    }
};

const handlePageSelect = (page) => {
    onSelectLink({ type: 'page', url: `/${page.slug}`, title: page.title });
    onClose();
};

const handlePostSelect = (post) => {
    onSelectLink({ type: 'blogPost', url: `/${post.slug}`, title: post.title });
    onClose();
};

const handleExternalSubmit = () => {
    if (externalUrl) {
      onSelectLink({ type: 'external', url: externalUrl });
      onClose();
    }
};

return (
    <Modal show={show} onHide={onClose} size="lg" className="color-picker link-builder">
        <Modal.Header closeButton>
            <Modal.Title>Link Builder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => {
                    setActiveTab(k);
                    setSelectedBlog(null);
                    setPosts([]);
                }}
            >
                <Tab eventKey="external" title="External Link">
                    <Form.Group className="mt-3 carousel-wrap pb-3">
                        <Form.Label>External URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter URL"
                            value={externalUrl}
                            onChange={(e) => setExternalUrl(e.target.value)}
                        />
                        <Button className="btn btn-secondary btn-sm" onClick={handleExternalSubmit}>Add Link</Button>
                    </Form.Group>
                </Tab>
                <Tab eventKey="pages" title="Pages">
                    <div className="mt-3 links-select-wrap">
                        <h4>Click below to choose link:</h4>
                        {loadingPages ? (
                            <Spinner animation="border" />
                        ) : (
                            <ListGroup>
                                {pages.map((page) => (
                                    <ListGroup.Item key={page.id} action onClick={() => handlePageSelect(page)}>
                                        {page.title}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </div>
                </Tab>
                <Tab eventKey="blog" title="Blog Posts">
                    <div className="mt-3 links-select-wrap">
                        <h4>Click below to choose blogs:</h4>
                        {loadingBlogs ? (
                            <Spinner animation="border" />
                        ) : (
                            <>
                                {!selectedBlog ? (
                                    <ListGroup>
                                        {blogs.map((blog) => (
                                            <ListGroup.Item key={blog.id} action onClick={() => {
                                                setSelectedBlog(blog);
                                                fetchPosts(blog.id);
                                            }}>
                                                {blog.title}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                ) : (
                                <>  
                                    <div className="d-flex mb-2">
                                        <h4 className="mt-2 pb-0">Click below to choose posts in {selectedBlog.title}</h4>
                                        <button className="ms-auto btn btn-dark btn-sm" onClick={() => { setSelectedBlog(null); setPosts([]); }}>
                                            <FontAwesomeIcon
                                                icon={faChevronLeft}
                                                className="me-2"
                                            />
                                            Back to Blogs
                                        </button>
                                    </div>

                                    {loadingPosts ? (
                                        <Spinner animation="border" />
                                    ) : (
                                        <ListGroup>
                                            {posts.map((post) => (
                                                <ListGroup.Item key={post.id} action onClick={() => handlePostSelect(post)}>
                                                    {post.title}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </>
                            )}
                            </>
                        )}
                    </div>
                </Tab>
            </Tabs>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>
  );
};

export default LinkBuilder;
