import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function ImageComponent({ imgSrc, styles = {}, template = "Default" }) {
    const containerClassName = `${template.toLowerCase()}-image`; // Class for parent div based on template
    
    const appliedStyles = useResponsiveStyles(styles);

    const imageStyles = appliedStyles?.style || {}; // Inline styles for the image

    const containerStyles = appliedStyles?.container || {}; // Inline styles for the image


    // Wrap image in a link if the `link` property is present
    const imageElement = (
        <img 
            src={imgSrc} 
            alt="Dynamic Content" 
            style={imageStyles} 
        />
    );

    return (
        <div className={containerClassName}  style={containerStyles} > 
            {imageStyles.link ? (
                <a href={imageStyles.link} target="_blank" rel="noopener noreferrer">
                    {imageElement}
                </a>
            ) : (
                imageElement
            )}
        </div>
    );
}

export default ImageComponent;