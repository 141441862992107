import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Spinner, Alert } from 'react-bootstrap';
import { API_URL } from '../../../config'; // Adjust the path as needed
import { useAppData } from '../AppDataContext';

const BlogsForm = ({ formValues, setFormValues }) => {
  const [availableBlogs, setAvailableBlogs] = useState([]);
  const [loadingBlogs, setLoadingBlogs] = useState(true);
  const [errorBlogs, setErrorBlogs] = useState(null);

  const { subdomain } = useAppData();

  /**
   * Fetch the list of all blogs (for the given account/subdomain).
   */
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoadingBlogs(true);
        const response = await fetch(`${API_URL}/blogs?account_key=${subdomain}`);
        const data = await response.json();

        if (response.ok && data.success) {
          setAvailableBlogs(data.blogs); // Adjust based on your API response structure
        } else {
          throw new Error(data.message || 'Failed to fetch blogs.');
        }
      } catch (err) {
        console.error('Error fetching blogs:', err);
        setErrorBlogs(err.message);
      } finally {
        setLoadingBlogs(false);
      }
    };

    fetchBlogs();
  }, [subdomain]);

  /**
   * Convert the fetched blogs to a format suitable for React-Select.
   */
  const blogOptions = availableBlogs.map((blog) => ({
    value: blog.id,          // Adjust if your blog has a different key
    label: blog.title,       // Adjust if your blog has a different display field
  }));

  /**
   * Handle changes in the blog selection (single select).
   */
  const handleBlogChange = (selectedOption) => {
    setFormValues({
      ...formValues,
      selectedBlog: selectedOption,
    });
  };

  return (
    <div className="blog-listing-form">

      {/* Error Handling for Blogs */}
      {errorBlogs && (
        <Alert variant="danger" onClose={() => setErrorBlogs(null)} dismissible>
          {errorBlogs}
        </Alert>
      )}

      {/* Blog Selection (Single Select) */}
      <Form.Group controlId="selectedBlog" className="mb-3">
        <Form.Label>Select a Blog</Form.Label>
        {loadingBlogs ? (
          <div className="d-flex align-items-center">
            <Spinner animation="border" role="status" className="me-2" />
            <span>Loading blogs...</span>
          </div>
        ) : (
          <Select
            options={blogOptions}
            value={formValues.selectedBlog || null}
            onChange={handleBlogChange}
            placeholder="Select a blog..."
            isClearable
          />
        )}
      </Form.Group>

    </div>
  );
};

export default BlogsForm;
