import React, { useEffect, useState, useMemo } from 'react';
import { Spinner, Alert, Card, Button } from 'react-bootstrap';
import { API_URL } from '../../../config'; // Adjust the path as needed
import { useAppData } from '../AppDataContext';
import { useResponsiveStyles } from './useResponsiveStyles'; // Import the hook
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types'; // Optional: For prop type checking
import { Link } from 'react-router-dom'; // For internal routing
import Masonry from 'react-masonry-css';

const Blogs = ({ selectedBlog = null, styles = {}, template = "Default" }) => {
    const [blog, setBlog] = useState(null); // State for blog details
    const [categories, setCategories] = useState([]);
    const [posts, setPosts] = useState([]);
    const [originalPosts, setOriginalPosts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { subdomain } = useAppData();

    // Apply responsive styles
    const appliedStyles = useResponsiveStyles(styles);

    // Breakpoints for Masonry
    const breakpointColumnsObj = {
        default: 1,
        992: 1,
        576: 1,
    };

    // Destructure styles for different parts
    const containerStyles = appliedStyles?.container || {};
    const cardStyles = appliedStyles?.card || {};
    const titleStyles = appliedStyles?.title || {};
    const contentStyles = appliedStyles?.content || {};
    const buttonStyles = appliedStyles?.button || {};
    const imageStyles = appliedStyles?.image || {};

    // New styles for category filter buttons
    const filterButtonStyles = appliedStyles?.filter || {};
    const activeFilterButtonStyles = appliedStyles?.activeFilter || {};

    useEffect(() => {
        const fetchBlogData = async () => {
            if (!selectedBlog) {
                setBlog(null);
                setCategories([]);
                setPosts([]);
                setOriginalPosts([]);
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                setError(null);

                const blogId = selectedBlog.value;
                const response = await fetch(
                    `${API_URL}/blog/${blogId}?account_key=${subdomain}`
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch blog details');
                }

                const data = await response.json();
                setBlog(data.blog || {}); // Store blog details
                setCategories(data.categories || []);
                setPosts(data.posts || []);
                setOriginalPosts(data.posts || []);
            } catch (err) {
                console.error('Error fetching blog data:', err);
                setError(err.message || 'An error occurred while fetching blog data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchBlogData();
    }, [selectedBlog, subdomain]);

    const handleCategoryFilter = (categoryId) => {
        setSelectedCategory(categoryId);
        if (!categoryId) {
            setPosts(originalPosts);
        } else {
            const filtered = originalPosts.filter((post) =>
                post.categories?.some((c) => c.id === categoryId)
            );
            setPosts(filtered);
        }
    };

    // Memoize posts to optimize performance
    const memoizedPosts = useMemo(() => posts, [posts]);

    if (isLoading) {
        return (
            <div className="text-center my-5" style={containerStyles}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="danger" className="text-center my-5" style={containerStyles}>
                {error}
            </Alert>
        );
    }

    if (!blog) {
        return (
            <div className="text-center my-5" style={containerStyles}>
                <p>No blog selected.</p>
            </div>
        );
    }

    switch (template) {
        case "SideBySide":
            return (
                <div className="sidebyside-bl blog-component" style={containerStyles}>
                    {/* Main Content */}
                    <div className="container">
                        {/* Categories */}
                        {categories.length > 0 && (
                            <div className="blog-category-wrap">
                                <button
                                    onClick={() => handleCategoryFilter(null)}
                                    className={
                                        selectedCategory === null
                                            ? 'btn btn-primary'
                                            : 'btn btn-outline-secondary'
                                    }
                                    style={selectedCategory === null ? activeFilterButtonStyles : filterButtonStyles}
                                >
                                    All
                                </button>
                                {categories.map((cat) => (
                                    <button
                                        key={cat.id}
                                        onClick={() => handleCategoryFilter(cat.id)}
                                        className={
                                            selectedCategory === cat.id
                                                ? 'btn btn-primary'
                                                : 'btn btn-outline-secondary'
                                        }
                                        style={selectedCategory === cat.id ? activeFilterButtonStyles : filterButtonStyles}
                                    >
                                        {cat.name}
                                    </button>
                                ))}
                            </div>
                        )}

                        {/* Posts with Masonry & Animations */}
                        <div className="blogs-list">
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-blog-grid"
                                columnClassName="my-blog-grid_column"
                            >
                                <TransitionGroup component={null}>
                                    {posts.length > 0 ? (
                                        posts.map((post) => (
                                            <CSSTransition key={post.id} timeout={300} classNames="fade">
                                                <Card style={{ ...cardStyles }}>
                                                    {post.featured_image_url && (
                                                        <Card.Img
                                                            src={post.featured_image_url}
                                                            className="card-img-top"
                                                            alt={post.title}
                                                            style={{ ...imageStyles }}
                                                            loading="lazy"
                                                        />
                                                    )}
                                                    <div className="card-body" style={{ ...cardStyles }}>
                                                        <h5 className="card-title" style={{ ...titleStyles }}>
                                                            {post.title}
                                                        </h5>
                                                        <hr />
                                                        <p className="card-text" style={{ ...contentStyles }}>
                                                            {post.excerpt?.length > 300
                                                                ? `${post.excerpt.substring(0, 300)}...`
                                                                : post.excerpt}
                                                        </p>
                                                        <div>
                                                            <a
                                                                href={`/blog/${post.slug}`}
                                                                className="btn btn-primary"
                                                                style={buttonStyles}
                                                            >
                                                                Read More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </CSSTransition>
                                        ))
                                    ) : (
                                        <p className="text-muted">No posts found for this category.</p>
                                    )}
                                </TransitionGroup>
                            </Masonry>
                        </div>
                    </div>
                </div>
            );

        case "Horizontal":
            return (
                <div className="horizontal-bl blog-component" style={containerStyles}>
                    {/* Main Content */}
                    <div className="container">
                        {/* Categories */}
                        {categories.length > 0 && (
                            <div className="blog-category-wrap">
                                <button
                                    onClick={() => handleCategoryFilter(null)}
                                    className={
                                        selectedCategory === null
                                            ? 'btn btn-primary'
                                            : 'btn btn-outline-secondary'
                                    }
                                    style={selectedCategory === null ? activeFilterButtonStyles : filterButtonStyles}
                                >
                                    All
                                </button>
                                {categories.map((cat) => (
                                    <button
                                        key={cat.id}
                                        onClick={() => handleCategoryFilter(cat.id)}
                                        className={
                                            selectedCategory === cat.id
                                                ? 'btn btn-primary'
                                                : 'btn btn-outline-secondary'
                                        }
                                        style={selectedCategory === cat.id ? activeFilterButtonStyles : filterButtonStyles}
                                    >
                                        {cat.name}
                                    </button>
                                ))}
                            </div>
                        )}

                        {/* Posts with Masonry & Animations */}
                        <div className="blogs-list">
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-blog-grid"
                                columnClassName="my-blog-grid_column"
                            >
                                <TransitionGroup component={null}>
                                    {posts.length > 0 ? (
                                        posts.map((post) => (
                                            <CSSTransition key={post.id} timeout={300} classNames="fade">
                                                <Card style={{ ...cardStyles }}>
                                                    {post.featured_image_url && (
                                                        <Card.Img
                                                            src={post.featured_image_url}
                                                            className="card-img-top"
                                                            alt={post.title}
                                                            style={{ ...imageStyles }}
                                                            loading="lazy"
                                                        />
                                                    )}
                                                    <div className="card-body" style={{ ...cardStyles }}>
                                                        <h5 className="card-title" style={{ ...titleStyles }}>
                                                            {post.title}
                                                        </h5>
                                                        <p className="card-text" style={{ ...contentStyles }}>
                                                            {post.excerpt?.length > 300
                                                                ? `${post.excerpt.substring(0, 300)}...`
                                                                : post.excerpt}
                                                        </p>
                                                        <div className="mt-auto">
                                                            <a
                                                                href={`/blog/${post.slug}`}
                                                                className="btn btn-primary"
                                                                style={buttonStyles}
                                                            >
                                                                Read More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </CSSTransition>
                                        ))
                                    ) : (
                                        <p className="text-muted">No posts found for this category.</p>
                                    )}
                                </TransitionGroup>
                            </Masonry>
                        </div>
                    </div>
                </div>
            );

        default:
            return (
                <div className="default-bl blog-component" style={containerStyles}>
                    {/* Main Content */}
                    <div className="container">
                        {/* Categories */}
                        {categories.length > 0 && (
                            <div className="blog-category-wrap">
                                <button
                                    onClick={() => handleCategoryFilter(null)}
                                    className={
                                        selectedCategory === null
                                            ? 'btn btn-primary'
                                            : 'btn btn-outline-secondary'
                                    }
                                    style={selectedCategory === null ? activeFilterButtonStyles : filterButtonStyles}
                                >
                                    All
                                </button>
                                {categories.map((cat) => (
                                    <button
                                        key={cat.id}
                                        onClick={() => handleCategoryFilter(cat.id)}
                                        className={
                                            selectedCategory === cat.id
                                                ? 'btn btn-primary'
                                                : 'btn btn-outline-secondary'
                                        }
                                        style={selectedCategory === cat.id ? activeFilterButtonStyles : filterButtonStyles}
                                    >
                                        {cat.name}
                                    </button>
                                ))}
                            </div>
                        )}

                        {/* Posts with Masonry & Animations */}
                        <div className="blogs-list">
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-blog-grid"
                                columnClassName="my-blog-grid_column"
                            >
                                <TransitionGroup component={null}>
                                    {posts.length > 0 ? (
                                        posts.map((post) => (
                                            <CSSTransition key={post.id} timeout={300} classNames="fade">
                                                <Card style={{ ...cardStyles }}>
                                                    {post.featured_image_url && (
                                                        <Card.Img
                                                            src={post.featured_image_url}
                                                            className="card-img-top"
                                                            alt={post.title}
                                                            style={{ ...imageStyles }}
                                                            loading="lazy"
                                                        />
                                                    )}
                                                    <div className="card-body d-flex flex-column" style={{ ...cardStyles }}>
                                                        <h5 className="card-title" style={{ ...titleStyles }}>
                                                            {post.title}
                                                        </h5>
                                                        <p className="card-text text-muted" style={{ ...contentStyles }}>
                                                            {post.excerpt?.length > 200
                                                                ? `${post.excerpt.substring(0, 200)}...`
                                                                : post.excerpt}
                                                        </p>
                                                        <div className="mt-auto">
                                                            <a
                                                                href={`/blog/${post.slug}`}
                                                                className="btn btn-primary"
                                                                style={buttonStyles}
                                                            >
                                                                Read More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </CSSTransition>
                                        ))
                                    ) : (
                                        <p className="text-muted">No posts found for this category.</p>
                                    )}
                                </TransitionGroup>
                            </Masonry>
                        </div>
                    </div>
                </div>
            );
    }
};

Blogs.propTypes = {
    selectedBlog: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
    }),
    styles: PropTypes.object,
};

export default Blogs;
