// StyleEditor.jsx
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, InputGroup, Image, Accordion } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import MediaLibrary from './MediaLibrary'; // Adjust the path as necessary
import ColorPicker from './ColorPicker'; // Import the ColorPicker component
import ColorPickerModal from './ColorPickerModal'; // Import the ColorPickerModal component
import LinkBuilder from './LinkBuilder';


// Custom Option component to apply fontFamily
const FontOption = (props) => (
    <components.Option {...props}>
        <div style={{ fontFamily: props.data.value }}>{props.label}</div>
    </components.Option>
);

// Custom SingleValue component to apply fontFamily to selected value
const FontSingleValue = (props) => (
    <components.SingleValue {...props}>
        <div style={{ fontFamily: props.data.value }}>{props.data.label}</div>
    </components.SingleValue>
);

const styleCategories = {
    Typography: [
        'fontSize',
        'fontFamily',
        'fontWeight',
        'fontStyle',
        'lineHeight',
        'letterSpacing',
        'textAlign',
        'textDecoration',
        'color',
        'textTransform',
        'textShadow',

    ],
    Spacing: ['margin', 'padding', 'gap'],
    Borders: ['border', 'borderRadius', 'borderColor', 'borderWidth'],
    Background: [
        'backgroundColor',
        'backgroundImage',
        'backgroundSize',
        'backgroundPosition',
        'backgroundRepeat',
        'backgroundAttachment'
    ],
    Layout: ['width', 'height', 'alignItems', 'justifyContent', 'alignment', 'sizing'],
    BoxShadow: ['boxShadow'],
    OverlayStyle: ['overlayBackgroundImage', 'overlayColor',  'overlayOpacity', 'overlayWidth', 'overlayHeight'],
    Links: ['link'], 
    Visibility: ['display'],
    skipAIContent: ['skipAIContent'],


};

const styleInputTypes = {
    // Numeric fields with steppers
    fontSize: 'stepper',
    borderRadius: 'side-stepper', 
    margin: 'side-stepper',
    padding: 'side-stepper',
    width: 'stepper',
    height: 'stepper',

    // Dropdown fields
    fontFamily: 'dropdown',
    fontWeight: 'dropdown',
    fontStyle: 'dropdown',
    lineHeight: 'stepper',
    letterSpacing: 'stepper',
    textAlign: 'dropdown',
    textDecoration: 'dropdown',
    overflow: 'dropdown',
    cursor: 'dropdown',
    alignment: 'dropdown',
    display: 'dropdown', // Ensure 'display' is set to 'dropdown'
    skipAIContent: 'dropdown',
    textTransform: 'dropdown', // Newly added

    // Color pickers
    color: 'color',
    backgroundColor: 'color',
    dotColor: 'color',
    arrowColor: 'color',
    borderColor: 'color',
    borderLeftColor: 'color',
    link: 'link',
    boxShadow: 'boxShadow',
    textShadow: 'textShadow',
    border: 'border',
    opacity: 'text',
    backgroundImage: 'backgroundImage',
    backgroundSize: 'dropdown',
    backgroundPosition: 'dropdown',
    backgroundRepeat: 'dropdown',
    backgroundAttachment: 'dropdown',
    overlayColor: 'color',
    overlayOpacity: 'stepperOpacity',
    overlayWidth: 'stepper',
    overlayHeight: 'stepper',
    overlayBackgroundImage: 'backgroundImage',

};

const options = {
    fontFamily: [
        { label: 'Arial', value: 'Arial, sans-serif' },
        { label: 'Georgia', value: 'Georgia, serif' },
        { label: 'Times New Roman', value: '"Times New Roman", Times, serif' },
        { label: 'Courier New', value: '"Courier New", Courier, monospace' },
        { label: 'Verdana', value: 'Verdana, sans-serif' },
        { label: 'Roboto', value: 'Roboto, sans-serif' },
        { label: 'Montserrat', value: 'Montserrat, sans-serif' },
        { label: 'Open Sans', value: 'Open Sans, sans-serif' },
        { label: 'Lato', value: 'Lato, sans-serif' },
        { label: 'Poppins', value: 'Poppins, sans-serif' },
        { label: 'Raleway', value: 'Raleway, sans-serif' },
        { label: 'Oswald', value: 'Oswald, sans-serif' },
        { label: 'Merriweather', value: 'Merriweather, serif' },
        { label: 'Playfair Display', value: 'Playfair Display, serif' },
        { label: 'Nunito', value: 'Nunito, sans-serif' },
        { label: 'Source Sans Pro', value: "'Source Sans Pro', sans-serif" },
        { label: 'Inter', value: "'Inter', sans-serif" },
        { label: 'Noto Sans', value: "'Noto Sans', sans-serif" },
        { label: 'Ubuntu', value: "'Ubuntu', sans-serif" },
        { label: 'Fira Sans', value: "'Fira Sans', sans-serif" },
        { label: 'PT Sans', value: "'PT Sans', sans-serif" },
        { label: 'Josefin Sans', value: "'Josefin Sans', sans-serif" },
        { label: 'Hind', value: "'Hind', sans-serif" },
        { label: 'Cabin', value: "'Cabin', sans-serif" },
        { label: 'Libre Baskerville', value: "'Libre Baskerville', serif" },
        { label: 'Muli', value: "'Muli', sans-serif" },
        { label: 'Rubik', value: "'Rubik', sans-serif" },
        { label: 'Work Sans', value: "'Work Sans', sans-serif" },
        { label: 'Ubuntu Mono', value: "'Ubuntu Mono', monospace" },
        { label: 'Roboto Slab', value: "'Roboto Slab', serif" },
        { label: 'Titillium Web', value: "'Titillium Web', sans-serif" },
        { label: 'Nunito Sans', value: "'Nunito Sans', sans-serif" },
        { label: 'Exo 2', value: "'Exo 2', sans-serif" },
        { label: 'Maven Pro', value: "'Maven Pro', sans-serif" },
        { label: 'Space Grotesk', value: "'Space Grotesk', sans-serif" },
        { label: 'Kavoon', value: "'Kavoon', cursive, sans-serif" },
        { label: 'Anton', value: "'Anton', sans-serif" },
        { label: 'Bricolage Grotesque', value: "'Bricolage Grotesque', sans-serif" }, 
        { label: 'Sora', value: "'Sora', sans-serif" },
    ],
    fontWeight: [
        'normal',
        'bold',
        'bolder',
        'lighter',
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
    ],
    fontStyle: ['normal', 'italic', 'oblique'],
    lineHeight: ['normal', '1', '1.2', '1.5', '1.6', '1.8', '2', '2.5', '3'],
    letterSpacing: [
        'normal',
        '0px',
        '1px',
        '2px',
        '3px',
        '4px',
        '5px',
        '10px',
    ],
    textAlign: ['left', 'center', 'right', 'justify'],
    textDecoration: ['none', 'underline', 'line-through', 'overline', 'dotted'],
    overflow: ['visible', 'hidden', 'scroll', 'auto'],
    cursor: ['default', 'pointer', 'move', 'not-allowed', 'help', 'text'],
    alignment: ['left', 'center', 'right'],
    backgroundSize: [
        { label: 'Auto', value: 'auto' },
        { label: 'Cover', value: 'cover' },
        { label: 'Contain', value: 'contain' },
        { label: '100% 100%', value: '100% 100%' },
        { label: 'Initial', value: 'initial' },
        { label: 'Inherit', value: 'inherit' },
        { label: 'Custom', value: 'custom' },

    ],
    backgroundPosition: [
        { label: 'Top Left', value: 'top left' },
        { label: 'Top Center', value: 'top center' },
        { label: 'Top Right', value: 'top right' },
        { label: 'Center Left', value: 'center left' },
        { label: 'Center', value: 'center' },
        { label: 'Center Right', value: 'center right' },
        { label: 'Bottom Left', value: 'bottom left' },
        { label: 'Bottom Center', value: 'bottom center' },
        { label: 'Bottom Right', value: 'bottom right' },
    ],
    backgroundRepeat: [
        { label: 'Repeat', value: 'repeat' },
        { label: 'Repeat-x', value: 'repeat-x' },
        { label: 'Repeat-y', value: 'repeat-y' },
        { label: 'No Repeat', value: 'no-repeat' },
        { label: 'Space', value: 'space' },
        { label: 'Round', value: 'round' },
    ],
    backgroundAttachment: [
        { label: 'Scroll', value: 'scroll' },
        { label: 'Fixed', value: 'fixed' },
        { label: 'Local', value: 'local' },
    ],
    textTransform: [ // Newly added
        { label: 'None', value: 'none' },
        { label: 'Uppercase', value: 'uppercase' },
        { label: 'Lowercase', value: 'lowercase' },
        { label: 'Capitalize', value: 'capitalize' },
    ],
    display: [ // Newly added
        { label: 'None', value: 'none' }, // Allows toggling visibility
    ],
    skipAIContent: [
        { label: 'Skip', value: 'yes' },
    ],
};

const formatLabel = (field) =>
    field
        .replace(/([A-Z])/g, ' $1') // Add a space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
        .toLowerCase() // Convert everything to lowercase
        .replace(/^\w/, (str) => str.toUpperCase()); // Ensure first word starts uppercase

const StyleEditor = ({
    styles = {},
    defaultStyles = {},
    onChange,
    editableFields = [],
    activeBreakpoint, 
}) => {
    const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  
    // State for ColorPickerModal
    const [showColorPickerModal, setShowColorPickerModal] = useState(false);
    const [showLinkBuilder, setShowLinkBuilder] = useState(false);
    const [currentColorField, setCurrentColorField] = useState(null);

    const [isCustomBackgroundSize, setIsCustomBackgroundSize] = useState(false);
    const [customBackgroundSize, setCustomBackgroundSize] = useState('');

    const [boxShadowValues, setBoxShadowValues] = useState({
        inset: false,
        offsetX: 0,
        offsetY: 0,
        blur: 10,
        spread: 0,
        color: '#000000',
    });


const [textShadowValues, setTextShadowValues] = useState({
    offsetX: 0,
    offsetY: 0,
    blur: 0,
    color: '#000000',
});

// Parse initial textShadow from styles (if any)
useEffect(() => {
    parseTextShadow(styles.textShadow || '');
}, [styles.textShadow]);

const parseTextShadow = (shadowStr) => {
    if (!shadowStr) {
        setTextShadowValues({
            offsetX: 0,
            offsetY: 0,
            blur: 0,
            color: '#000000',
        });
        return;
    }

    // Example format: "2px 2px 5px #000000"
    const parts = shadowStr.split(/\s+/);
    let offsetX = 0;
    let offsetY = 0;
    let blur = 0;
    let color = '#000000';

    if (parts.length >= 4) {
        offsetX = parseInt(parts[0], 10) || 0;
        offsetY = parseInt(parts[1], 10) || 0;
        blur = parseInt(parts[2], 10) || 5;
        color = parts.slice(3).join(' ') || '#000000';
    } else if (parts.length === 3) {
        offsetX = parseInt(parts[0], 10) || 0;
        offsetY = parseInt(parts[1], 10) || 0;
        blur = parseInt(parts[2], 10) || 5;
        color = '#000000'; // Default color
    } else {
        // Fallback
        offsetX = 0;
        offsetY = 0;
        blur = 0;
        color = shadowStr; // Best guess
    }

    setTextShadowValues({
        offsetX,
        offsetY,
        blur,
        color,
    });
};

const constructTextShadow = (values) => {
    const { offsetX, offsetY, blur, color } = values;
    return `${offsetX}px ${offsetY}px ${blur}px ${color}`;
};


const handleTextShadowChange = (key, value) => {
    const newValues = { ...textShadowValues, [key]: value };
    setTextShadowValues(newValues);
    onChange({ ...styles, textShadow: constructTextShadow(newValues) });
};


const resetTextShadow = () => {
    const defaultTextShadow = defaultStyles.textShadow || '';
    parseTextShadow(defaultTextShadow);
    onChange({ ...styles, textShadow: defaultTextShadow });
};

const handleTextShadowColorChange = (newColor) => {
    setTextShadowValues((prev) => {
        const updated = { ...prev, color: newColor };
        onChange({ ...styles, textShadow: constructTextShadow(updated) });
        return updated;
    });
};

  // Parse initial boxShadow from styles (if any)
  useEffect(() => {
    parseBoxShadow(styles.boxShadow || '');
  }, [styles.boxShadow]);

  const parseBoxShadow = (shadowStr) => {
    if (!shadowStr) {
      setBoxShadowValues({
        inset: false,
        offsetX: 0,
        offsetY: 0,
        blur: 10,
        spread: 0,
        color: '#000000',
      });
      return;
    }

    // Check for 'inset'
    const hasInset = shadowStr.includes('inset');
    let withoutInset = shadowStr.replace('inset', '').trim();

    // Splitting by spaces can be naive, but for a basic approach:
    const parts = withoutInset.split(/\s+/);
    // Expecting something like: "10px 10px 5px 0px rgba(0,0,0,0.3)" or "#000000"
    // We'll do a minimal parse:
    let offsetX = 0;
    let offsetY = 0;
    let blur = 10;
    let spread = 0;
    let color = '#000000';

    // Because colors might have spaces if it's rgba(...) etc, let's pick from start:
    // offsetX = parts[0], offsetY = parts[1], blur = parts[2], spread = parts[3], color = remainder
    if (parts.length >= 4) {
      offsetX = parseInt(parts[0], 10) || 0;
      offsetY = parseInt(parts[1], 10) || 0;
      blur = parseInt(parts[2], 10) || 10;
      spread = parseInt(parts[3], 10) || 0;
      color = parts.slice(4).join(' ') || '#000000';
    } else if (parts.length >= 3) {
      // Possibly offsetX, offsetY, and color
      offsetX = parseInt(parts[0], 10) || 0;
      offsetY = parseInt(parts[1], 10) || 0;
      blur = 0;
      spread = 0;
      color = parts.slice(2).join(' ') || '#000000';
    } else {
      // fallback
      offsetX = 0;
      offsetY = 0;
      blur = 10;
      spread = 0;
      color = shadowStr; // best guess
    }

    setBoxShadowValues({
      inset: hasInset,
      offsetX,
      offsetY,
      blur,
      spread,
      color,
    });
  };

  const constructBoxShadow = () => {
    const { inset, offsetX, offsetY, blur, spread, color } = boxShadowValues;
    const insetStr = inset ? 'inset ' : '';
    return `${insetStr}${offsetX}px ${offsetY}px ${blur}px ${spread}px ${color}`.trim();
  };

  const handleBoxShadowChange = (key, value) => {
    const newValues = { ...boxShadowValues, [key]: value };
    setBoxShadowValues(newValues);
    // Construct the new boxShadow string and update styles
    onChange({ ...styles, boxShadow: buildBoxShadowString(newValues) });
  };

  const buildBoxShadowString = (vals) => {
    const { inset, offsetX, offsetY, blur, spread, color } = vals;
    const insetStr = inset ? 'inset ' : '';
    return `${insetStr}${offsetX}px ${offsetY}px ${blur}px ${spread}px ${color}`.trim();
  };

  const resetBoxShadow = () => {
    const defaultBoxShadow = defaultStyles.boxShadow || ''; 
    parseBoxShadow(defaultBoxShadow); 
    onChange({ ...styles, boxShadow: defaultBoxShadow });
  };

  const [colorPickerForBoxShadow, setColorPickerForBoxShadow] = useState(false);

  const handleBoxShadowColorChange = (newColor) => {
    // If color picker is specifically for boxShadow color:
    setBoxShadowValues((prev) => {
      const updated = { ...prev, color: newColor };
      onChange({ ...styles, boxShadow: buildBoxShadowString(updated) });
      return updated;
    });
  };
    const handleStyleChange = (field, value) => {
        const updatedStyles = { ...styles, [field]: value };
        // If one of the borderRadius side values is updated,
        // combine them into a single 'borderRadius' property.
        if (
            field === 'borderRadiusTop' ||
            field === 'borderRadiusRight' ||
            field === 'borderRadiusBottom' ||
            field === 'borderRadiusLeft'
        ) {
            const top = updatedStyles.borderRadiusTop || '0px';
            const right = updatedStyles.borderRadiusRight || '0px';
            const bottom = updatedStyles.borderRadiusBottom || '0px';
            const left = updatedStyles.borderRadiusLeft || '0px';
            updatedStyles.borderRadius = `${top} ${right} ${bottom} ${left}`;
        }
        onChange(updatedStyles);
    };

    const handleResetStyle = (field) => {
        onChange({ ...styles, [field]: defaultStyles[field] || '' });
        if (field === 'backgroundSize') {
            setIsCustomBackgroundSize(false);
            setCustomBackgroundSize('');
        }
    };

    const handleIncrement = (field, increment) => {
        const currentValue = parseInt(styles[field] || '0', 10);
        handleStyleChange(field, `${currentValue + increment}px`);
    };

    // Handlers for ColorPickerModal
    const openColorPickerModal = (field) => {
        setCurrentColorField(field);
        setShowColorPickerModal(true);
    };

    const closeColorPickerModal = () => {
        setShowColorPickerModal(false);
        setCurrentColorField(null);
    };

    const handleColorChange = (newColor) => {
        if (currentColorField) {
            handleStyleChange(currentColorField, newColor);
        }
    };

    const handleLinkSelect = (linkData) => {
      // Update the "link" field with the selected URL.
      handleStyleChange('link', linkData.url);
      setShowLinkBuilder(false);
    };

    // New handler for overlayOpacity (increments in 0.1, clamped between 0 and 1)
    const handleOpacityIncrement = (field, increment) => {
        const currentValue = parseFloat(styles[field] || '0');
        let newValue = currentValue + increment;
        if (newValue < 0) newValue = 0;
        if (newValue > 1) newValue = 1;
        handleStyleChange(field, newValue.toFixed(1));
    };

    const renderLinkField = (field) => (
      <>
        <div className="styling-label d-flex justify-content-between align-items-center">
          <Form.Label>{formatLabel(field)}</Form.Label>
          <Button variant="outline-secondary me-auto" size="sm" onClick={() => handleResetStyle(field)}>
            <FontAwesomeIcon icon={faSyncAlt} /> Reset
          </Button>
        </div>
        <InputGroup>
          <Form.Control
            type="text"
            readOnly
            value={styles[field] || ''}
            placeholder="No link selected"
          />
          <Button variant="outline-secondary" onClick={() => setShowLinkBuilder(true)}>
            Add Link
          </Button>
        </InputGroup>
      </>
    );


    const renderOpacityStepper = (field) => (
        <InputGroup className="stepper-style">
            <Button variant="outline-secondary" onClick={() => handleOpacityIncrement(field, -0.1)}>
                -
            </Button>
            <Form.Control
                type="text"
                value={styles[field] || ''}
                onChange={(e) => {
                    let val = parseFloat(e.target.value);
                    if (isNaN(val)) val = 0;
                    if (val < 0) val = 0;
                    if (val > 1) val = 1;
                    handleStyleChange(field, val.toFixed(1));
                }}
                className="text-center"
            />
            <Button variant="outline-secondary" onClick={() => handleOpacityIncrement(field, 0.1)}>
                +
            </Button>
        </InputGroup>
    );
    const renderStepper = (field) => (
        <InputGroup className="stepper-style">
            <Button
                variant="outline-secondary"
                onClick={() => handleIncrement(field, -1)}
            >
                -
            </Button>
            <Form.Control
                type="text"
                value={styles[field] || ''}
                onChange={(e) => {
                    const value = e.target.value;
                    handleStyleChange(field, value); // Directly pass the user input
                }}
                className="text-center"
            />
            <Button
                variant="outline-secondary"
                onClick={() => handleIncrement(field, 1)}
            >
                +
            </Button>
        </InputGroup>
    );

    const renderBorderEditor = (field) => (
        <div>
            <div className="styling-label d-flex justify-content-between align-items-center">
                <Form.Label>{formatLabel(field)}</Form.Label>
                {/* Single Reset All Button */}
                <Button
                    variant="outline-secondary me-auto"
                    size="sm"
                    onClick={() => {
                    const updatedStyles = { ...styles }; // Clone current styles
                    ['Top', 'Right', 'Bottom', 'Left'].forEach((side) => {
                        updatedStyles[`${field}${side}Width`] =
                            defaultStyles[`${field}${side}Width`] || '0px'; // Reset width
                        updatedStyles[`${field}${side}Style`] =
                            defaultStyles[`${field}${side}Style`] || 'none'; // Reset style
                        updatedStyles[`${field}${side}Color`] =
                            defaultStyles[`${field}${side}Color`] || '#000000'; // Reset color
                        });
                        onChange(updatedStyles); // Update styles
                    }}
                >
                    <FontAwesomeIcon icon={faSyncAlt} /> Reset All
                </Button>
            </div>

            {['Top', 'Right', 'Bottom', 'Left'].map((side) => (
                <div key={side} className="border-setting-model">
                    <h5>{`${side} Border`}</h5>
                    <Row>
                        <Col>
                            <InputGroup className="stepper-style">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => handleIncrement(`${field}${side}Width`, -1)}
                                >
                                    -
                                </Button>
                                <Form.Control
                                    type="text"
                                    value={styles[`${field}${side}Width`] || '0px'}
                                    onChange={(e) =>
                                        handleStyleChange(`${field}${side}Width`, e.target.value)
                                    }
                                    className="text-center"
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => handleIncrement(`${field}${side}Width`, 1)}
                                >
                                    +
                                </Button>
                            </InputGroup>
                            <Form.Label>{`Width`}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                value={styles[`${field}${side}Style`] || 'none'}
                                onChange={(e) =>
                                    handleStyleChange(`${field}${side}Style`, e.target.value)
                                }
                            >
                                <option value="none">None</option>
                                <option value="solid">Solid</option>
                                <option value="dashed">Dashed</option>
                                <option value="dotted">Dotted</option>
                                <option value="double">Double</option>
                            </Form.Control>
                            <Form.Label>{`Style`}</Form.Label>
                        </Col>
                        <Col>
                            <ColorPicker
                                field={`${field}${side}Color`}
                                color={styles[`${field}${side}Color`]}
                                onOpen={openColorPickerModal}
                            />
                            <Form.Label>{`Color`}</Form.Label>
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );

    const renderSideSteppers = (field) => (
        <div>
            <div className="styling-label d-flex justify-content-between align-items-center">
                <Form.Label>{formatLabel(field)}</Form.Label>
                <Button
                    variant="outline-secondary me-auto"
                    size="sm"
                    onClick={() => {
                        const updatedStyles = { ...styles }; // Clone current styles
                        ['Top', 'Right', 'Bottom', 'Left'].forEach((side) => {
                            updatedStyles[`${field}${side}`] =
                            defaultStyles[`${field}${side}`] || '0px'; // Reset to default or '0px'
                        });
                        onChange(updatedStyles); // Update all styles at once
                    }}
                >
                    <FontAwesomeIcon icon={faSyncAlt} /> Reset
                </Button>
            </div>
            <Row className="l-in-label">
                {['Top', 'Right', 'Bottom', 'Left'].map((side) => (
                    <Col key={side}>
                        {renderStepper(`${field}${side}`)}
                        <Form.Label className="text-center">{side}</Form.Label>
                    </Col>
                ))}
            </Row>
        </div>
    );

    const renderBackgroundImage = () => (
        <div>
            <div className="icon-card-form carousel-wrap pb-2">
                <div className="styling-label d-flex justify-content-between align-items-center">
                    <Form.Label>{formatLabel('backgroundImage')}</Form.Label>
                    <div>
                        <Button
                            variant="outline-secondary me-auto"
                            size="sm"
                            onClick={() => handleResetStyle('backgroundImage')}
                            className="me-2"
                        >
                            <FontAwesomeIcon icon={faSyncAlt} /> Reset
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="media-img-wrap">
                            {styles['backgroundImage'] && (
                                <div className="mt-2">
                                    <Image
                                        src={styles['backgroundImage'].replace(/url\(["']?/, '').replace(/["']?\)$/, '')}
                                        alt="Background"
                                        thumbnail
                                        style={{ width: '100px', height: '100px', ObjectFit: 'cover' }}
                                    />
                                </div>
                            )}
                            {/* MediaLibrary Modal */}
                            <MediaLibrary
                                onSelectImage={handleSelectBackgroundImage}
                                show={showMediaLibrary}
                                setShow={setShowMediaLibrary}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Render additional background settings */}
            <div className="mb-3 styling-form-inputs">
                {renderField('backgroundSize')}
            </div>
            <div className="mb-3 styling-form-inputs">
                {renderField('backgroundPosition')}
            </div>
            <div className="mb-3 styling-form-inputs">
                {renderField('backgroundRepeat')}
            </div>
            <div className="mb-3 styling-form-inputs">
                {renderField('backgroundAttachment')}
            </div> 
        </div>
    );

const renderTextShadowEditor = () => {
    const { offsetX, offsetY, blur, color } = textShadowValues;
    return (
        <>
            <div className="styling-label d-flex justify-content-between align-items-center">
                <Form.Label>{formatLabel('textShadow')}</Form.Label>
                <Button variant="outline-secondary me-auto" size="sm" onClick={resetTextShadow}>
                    <FontAwesomeIcon icon={faSyncAlt} /> Reset
                </Button>
            </div>

            <Row className="l-in-label mb-3">
                <Col xs={3}>
                    <InputGroup className="stepper-style">
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('offsetX', offsetX - 1)}
                        >
                            -
                        </Button>
                        <Form.Control
                            type="text"
                            value={offsetX}
                            onChange={(e) =>
                                handleTextShadowChange('offsetX', parseInt(e.target.value || '0', 10))
                            }
                            className="text-center"
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('offsetX', offsetX + 1)}
                        >
                            +
                        </Button>
                    </InputGroup>
                    <Form.Label className="text-center">Offset X</Form.Label>
                </Col>
                <Col xs={3}>
                    <InputGroup className="stepper-style">
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('offsetY', offsetY - 1)}
                        >
                            -
                        </Button>
                        <Form.Control
                            type="text"
                            value={offsetY}
                            onChange={(e) =>
                                handleTextShadowChange('offsetY', parseInt(e.target.value || '0', 10))
                            }
                            className="text-center"
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('offsetY', offsetY + 1)}
                        >
                            +
                        </Button>
                    </InputGroup>
                    <Form.Label className="text-center">Offset Y</Form.Label>
                </Col>
                <Col xs={3}>
                    <InputGroup className="stepper-style">
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('blur', blur - 1)}
                        >
                            -
                        </Button>
                        <Form.Control
                            type="text"
                            value={blur}
                            onChange={(e) =>
                                handleTextShadowChange('blur', parseInt(e.target.value || '0', 10))
                            }
                            className="text-center"
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleTextShadowChange('blur', blur + 1)}
                        >
                            +
                        </Button>
                    </InputGroup>
                    <Form.Label className="text-center">Blur</Form.Label>
                </Col>
                <Col xs={3}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ColorPicker
                            field="textShadowColor"
                            color={color}
                            onOpen={() => setShowColorPickerModal(true)}
                        />
                    </div>
                    <Form.Label className="text-center">Color</Form.Label>
                </Col>
            </Row>

            {/* Text Shadow Color Picker Modal */}
            {showColorPickerModal && (
                <ColorPickerModal
                    show={showColorPickerModal}
                    handleClose={() => setShowColorPickerModal(false)}
                    color={color}
                    handleColorChange={handleTextShadowColorChange}
                />
            )}
        </>
    );
};

  const renderBoxShadowEditor = () => {
    const { inset, offsetX, offsetY, blur, spread, color } = boxShadowValues;
    return (
      <>
        <div className="styling-label d-flex justify-content-between align-items-center">
          <Form.Label>{formatLabel('boxShadow')}</Form.Label>
          <Button variant="outline-secondary me-auto" size="sm" onClick={resetBoxShadow}>
            <FontAwesomeIcon icon={faSyncAlt} /> Reset
          </Button>
        </div>

        <Row className="l-in-label mb-3">
          <Col xs={3}>
            <InputGroup className="stepper-style">
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('offsetX', offsetX - 1)}
              >
                -
              </Button>
              <Form.Control
                type="text"
                value={offsetX}
                onChange={(e) =>
                  handleBoxShadowChange('offsetX', parseInt(e.target.value || '0', 10))
                }
                className="text-center"
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('offsetX', offsetX + 1)}
              >
                +
              </Button>
            </InputGroup>
            <Form.Label>{`Offset X`}</Form.Label>
          </Col>
          <Col xs={3}>
            <InputGroup className="stepper-style">
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('offsetY', offsetY - 1)}
              >
                -
              </Button>
              <Form.Control
                type="text"
                value={offsetY}
                onChange={(e) =>
                  handleBoxShadowChange('offsetY', parseInt(e.target.value || '0', 10))
                }
                className="text-center"
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('offsetY', offsetY + 1)}
              >
                +
              </Button>
            </InputGroup>
            <Form.Label>Offset Y</Form.Label>
          </Col>
          <Col xs={3}>
            <InputGroup className="stepper-style">
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('blur', blur - 1)}
              >
                -
              </Button>
              <Form.Control
                type="text"
                value={blur}
                onChange={(e) =>
                  handleBoxShadowChange('blur', parseInt(e.target.value || '0', 10))
                }
                className="text-center"
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('blur', blur + 1)}
              >
                +
              </Button>
            </InputGroup>
            <Form.Label>Blur</Form.Label>
          </Col>
          <Col xs={3}>
            <InputGroup className="stepper-style">
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('spread', spread - 1)}
              >
                -
              </Button>
              <Form.Control
                type="text"
                value={spread}
                onChange={(e) =>
                  handleBoxShadowChange('spread', parseInt(e.target.value || '0', 10))
                }
                className="text-center"
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleBoxShadowChange('spread', spread + 1)}
              >
                +
              </Button>
            </InputGroup>
            <Form.Label>Spread</Form.Label>
          </Col>
        </Row>


        <Row className="mb-3">
          <Col xs={12}>
            <Form.Label>Box Shadow Color</Form.Label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ColorPicker
                field="boxShadowColor"
                color={color}
                onOpen={() => setColorPickerForBoxShadow(true)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} className="d-flex">
            <Form.Check
              type="checkbox"
              checked={inset}
              onChange={(e) => handleBoxShadowChange('inset', e.target.checked)}
            />
            <Form.Label className="ps-2">Inset</Form.Label>
          </Col>
        </Row>

        {/* Box Shadow Color Picker Modal */}
        {colorPickerForBoxShadow && (
          <ColorPickerModal
            show={colorPickerForBoxShadow}
            handleClose={() => setColorPickerForBoxShadow(false)}
            color={color}
            handleColorChange={handleBoxShadowColorChange}
          />
        )}
      </>
    );
  };

const renderField = (field) => {
    if (styleInputTypes[field] === 'backgroundImage') {
        return renderBackgroundImage();
    }

    if (styleInputTypes[field] === 'border') {
        return renderBorderEditor(field);
    } else if (styleInputTypes[field] === 'side-stepper') {
        return renderSideSteppers(field);
    } else if (styleInputTypes[field] === 'boxShadow') {
        return renderBoxShadowEditor();
    } else if (styleInputTypes[field] === 'textShadow') {
        return renderTextShadowEditor();
    } else if (styleInputTypes[field] === 'link' || field === 'link') {
       return renderLinkField(field);
    } else if (styleInputTypes[field] === 'stepperOpacity') {
            // Wrap the opacity stepper with label and reset button
            return (
                <>
                    <div className="styling-label d-flex justify-content-between align-items-center">
                        <Form.Label>{formatLabel(field)}</Form.Label>
                        <Button variant="outline-secondary me-auto" size="sm" onClick={() => handleResetStyle(field)}>
                            <FontAwesomeIcon icon={faSyncAlt} /> Reset
                        </Button>
                    </div>
                    {renderOpacityStepper(field)}
                </>
            );
        } else if (field === 'backgroundSize') { // Handle backgroundSize specifically
        return (
            <div>
                <div className="styling-label d-flex justify-content-between align-items-center">
                    <Form.Label>{formatLabel(field)}</Form.Label>
                    <Button
                        variant="outline-secondary me-auto"
                        size="sm"
                        onClick={() => handleResetStyle(field)}
                    >
                        <FontAwesomeIcon icon={faSyncAlt} /> Reset
                    </Button>
                </div>
                <Select
                    components={{ Option: FontOption, SingleValue: FontSingleValue }}
                    options={options[field].map((option) => ({
                        label: option.label || option,
                        value: option.value || option,
                    }))}
                    value={
                        options[field]
                            .map((option) => ({
                                label: option.label || option,
                                value: option.value || option,
                            }))
                            .find((option) => option.value === styles[field]) || null
                    }
                    onChange={(selectedOption) => {
                        handleStyleChange(field, selectedOption ? selectedOption.value : '');
                        if (selectedOption.value === 'custom') {
                            setIsCustomBackgroundSize(true);
                            setCustomBackgroundSize(styles[field] || '');
                        } else {
                            setIsCustomBackgroundSize(false);
                        }
                    }}
                    placeholder="Select"
                    menuPortalTarget={document.body} // Render the menu in a portal
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            minHeight: '38px',
                            zIndex: 9999, // Ensure the control has a high z-index
                        }),
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 9999, // Ensure the menu appears on top
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            height: '38px',
                        }),
                    }}
                />
                {isCustomBackgroundSize && (
                    <InputGroup className="mt-2">
                        <Form.Control
                            type="text"
                            placeholder="Enter custom size (e.g., 50px 100px)"
                            value={customBackgroundSize}
                            onChange={(e) => {
                                const value = e.target.value;
                                setCustomBackgroundSize(value);
                                handleStyleChange(field, value);
                            }}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                handleStyleChange(field, customBackgroundSize || '');
                            }}
                        >
                            Apply
                        </Button>
                    </InputGroup>
                )}
            </div>
        );
    } else {
        return (
            <>
                <div className="styling-label d-flex justify-content-between align-items-center">
                    <Form.Label>{formatLabel(field)}</Form.Label>
                    <Button
                        variant="outline-secondary me-auto"
                        size="sm"
                        onClick={() => handleResetStyle(field)}
                    >
                        <FontAwesomeIcon icon={faSyncAlt} /> Reset
                    </Button>
                </div>
                {styleInputTypes[field] === 'stepper' ? (
                    renderStepper(field)
                ) : styleInputTypes[field] === 'dropdown' ? (
                    <Select
                        components={{ Option: FontOption, SingleValue: FontSingleValue }}
                        options={options[field].map((option) => ({
                            label: option.label || option,
                            value: option.value || option,
                        }))}
                        value={
                            options[field]
                            .map((option) => ({
                                label: option.label || option,
                                value: option.value || option,
                            }))
                            .find((option) => option.value === styles[field]) || null
                        }
                        onChange={(selectedOption) =>
                            handleStyleChange(field, selectedOption ? selectedOption.value : '')
                        }
                        placeholder="Select"
                        menuPortalTarget={document.body} // Render the menu in a portal
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                minHeight: '38px',
                                zIndex: 9999, // Ensure the control has a high z-index
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999, // Ensure the menu appears on top
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            indicatorsContainer: (provided) => ({
                                ...provided,
                                height: '38px',
                            }),
                        }}
                    />
                ) : styleInputTypes[field] === 'color' ? (
                    <ColorPicker
                        field={field}
                        color={styles[field]}
                        onOpen={openColorPickerModal}
                    />
                ) : (
                    <InputGroup>
                        <Form.Control
                            type="text"
                            name={field}
                            value={styles[field] || ''}
                            onChange={(e) => handleStyleChange(field, e.target.value)}
                            placeholder={
                                field === 'boxShadow'
                                ? 'e.g., 0px 4px 10px rgba(0,0,0,0.25)'
                                : ''
                            }
                        />
                    </InputGroup>
                )}
            </>
        );
    }
};

    const handleSelectBackgroundImage = (url) => {
        handleStyleChange('backgroundImage', `url("${url}")`);
        setShowMediaLibrary(false);
    };

    return (
        <>
            <Accordion defaultActiveKey="0">
                {Object.keys(styleCategories).map((category, idx) => {
                    
                if (activeBreakpoint === 'desktop' && category === 'Visibility') {
                    return null; // Skip rendering the 'Visibility' category
                }

                    // Filter fields based on editableFields
                    const filteredFields = styleCategories[category].filter((field) =>
                        editableFields.length === 0 || editableFields.includes(field)
                    );

                    // Skip categories with no editable fields
                    if (filteredFields.length === 0) return null;

                    return (
                        <Accordion.Item key={category} eventKey={idx}>
                            <Accordion.Header>{category}</Accordion.Header>
                            <Accordion.Body>
                                {filteredFields.map((field) => (
                                    <Form.Group key={field} className="mb-3 styling-form-inputs">
                                        {renderField(field)}
                                    </Form.Group>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
            </Accordion>

            {/* ColorPickerModal */}
            {currentColorField && (
                <ColorPickerModal
                    show={showColorPickerModal}
                    handleClose={closeColorPickerModal}
                    color={styles[currentColorField]}
                    handleColorChange={handleColorChange}
                />
            )}

            {showLinkBuilder && (
              <LinkBuilder
                show={showLinkBuilder}
                onClose={() => setShowLinkBuilder(false)}
                onSelectLink={handleLinkSelect}
              />
            )}

        </>
    );
};

export default StyleEditor;
