import React, { useEffect, useState } from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { useAppData } from '../AppDataContext';
import { API_URL } from '../../../config';
import { useResponsiveStyles } from './useResponsiveStyles'; // Import the hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Menus = ({
    selectedMenus = [],
    styles = {},
    template = "Horizontal" // Default to Horizontal
}) => {
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { subdomain } = useAppData();

    // Apply responsive styles using common keys:
    // menu, menuItem, subMenu, dropdownItem, toggle, spinner, alert, noMenusMessage
    const appliedStyles = useResponsiveStyles(styles);
    const menuStyles = appliedStyles?.menu || {};
    const menuItemStyles = appliedStyles?.menuItem || {};
    const menuItemLink = appliedStyles?.menuItemLink || {};

    const subMenuStyles = appliedStyles?.subMenu || {};
    const dropdownItemStylesLink = appliedStyles?.dropdownItemStylesLink || {};
    const dropdownItemStyles = appliedStyles?.dropdownItem || {};
    const toggleStyles = appliedStyles?.toggle || {};
    const spinnerStyles = appliedStyles?.spinner || {};
    const alertStyles = appliedStyles?.alert || {};
    const noMenusMessageStyles = appliedStyles?.noMenusMessage || {};

    // Fetch menus based on selectedMenus and subdomain
    useEffect(() => {
        const fetchMenus = async () => {
            try {
                setLoading(true);
                const menuIds = selectedMenus.map((menu) => menu.value);
                const response = await fetch(
                    `${API_URL}/menus/filter?account_key=${subdomain}&menus_id=${menuIds.join(',')}`
                );
                const data = await response.json();
                if (response.ok && Array.isArray(data)) {
                    setMenus(data);
                } else {
                    throw new Error(data.message || 'Failed to fetch menus.');
                }
            } catch (err) {
                console.error('Error fetching menus:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (selectedMenus.length > 0) {
            fetchMenus();
        } else {
            setMenus([]);
            setLoading(false);
        }
    }, [selectedMenus, subdomain]);

    // Function to generate URL based on link_type
    const getMenuLink = (menu) => {
        switch (menu.link_type) {
            case 'category':
                return `/products?cat=${menu.id}`;
            case 'product':
            case 'productListingPage':
                return '/products';
            case 'blog':
                return `/blog/${menu.blog_id}`;
            default:
                return menu.link || '#';
        }
    };

    // --- Horizontal Menu Renderers ---
    const renderHorizontalMenuItems = (menusList) => {
        return menusList.map((menu) => {
            const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
                return (
                    <li key={menu.id} className={`menu-item ${hasSubMenus ? "has-dropdown" : ""}`} style={menuItemStyles}>
                        <a 
                            href={getMenuLink(menu)} 
                            style={menuItemLink}
                        >
                            {menu.title}
                            {hasSubMenus && (
                                <>
                                    <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
                                </>
                            )}
                        </a>
                        {hasSubMenus && (
                            <>
                                <ul className="dropdown" style={subMenuStyles}>
                                    {renderHorizontalSubMenuItems(menu.subMenus)}
                                </ul>
                            </>
                        )}
                    </li>
                );
            });
        };
        const renderHorizontalSubMenuItems = (subMenus) => {
            return subMenus.map((subMenu) => {
                const hasSubMenus = subMenu.subMenus && subMenu.subMenus.length > 0;
                    return (
                        <li key={subMenu.id} className={`dropdown-item ${hasSubMenus ? "has-dropdown" : ""}`} style={dropdownItemStyles}>
                            <a href={getMenuLink(subMenu)} style={dropdownItemStylesLink}>
                                {hasSubMenus && (
                                    <>
                                        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
                                    </>
                                )}
                                {subMenu.title}
                            </a>
                            {hasSubMenus && (
                                <>
                                    <ul className="dropdown" style={subMenuStyles}>
                                        {renderHorizontalSubMenuItems(subMenu.subMenus)}
                                    </ul>
                                </>
                            )}
                        </li>
                    );
                });
            };

            const renderVerticalMenuItems = (menusList) => {
                return (
                    <ul className="menu" style={menuStyles}>
                        {menusList.map((menu) => {
                            const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
                            return (
                                <li key={menu.id} className="menu-item" style={menuItemStyles}>
                                    {hasSubMenus ? (
                                        <>
                                            <span style={{ cursor: 'pointer' }}>
                                                {menu.title} <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
                                            </span>
                                            {renderVerticalSubMenuItems(menu.subMenus)}
                                        </>
                                    ) : (
                                        <a href={getMenuLink(menu)} style={menuItemLink}>
                                            {menu.title}
                                        </a>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                );
            };
            const renderVerticalSubMenuItems = (subMenus) => {
                return (
                    <ul className="submenu" style={subMenuStyles}>
                        {subMenus.map((subMenu) => {
                            const hasSubMenus = subMenu.subMenus && subMenu.subMenus.length > 0;
                                return (
                                    <li key={subMenu.id} className="submenu-item" style={dropdownItemStyles}>
                                        {hasSubMenus ? (
                                            <>
                                                <span style={{ cursor: 'pointer' }}>
                                                    {subMenu.title} <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon"  />
                                                </span>
                                                {renderVerticalSubMenuItems(subMenu.subMenus)}
                                            </>
                                        ) : (
                                            <a href={getMenuLink(subMenu)} style={dropdownItemStyles}>
                                                {subMenu.title}
                                            </a>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                );
            };

            // --- Loading, Error, No Menus ---
            if (loading) {
                return (
                    <div className="text-center my-4" style={spinnerStyles}>
                        <Spinner animation="border" role="status" style={spinnerStyles}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                );
            }
            if (error) {
                return (
                    <div className="text-center my-4" style={alertStyles}>
                        <Alert variant="danger" style={alertStyles}>
                            {error}
                        </Alert>
                    </div>
                );
            }
            if (menus.length === 0) {
                return (
                    <div className="text-center my-4" style={noMenusMessageStyles}>
                        <p style={noMenusMessageStyles}>No menus found.</p>
                    </div>
                );
            }

            // --- Render Menu based on template ---
            return template === "Vertical" ? (
                <nav className="vertical-menu-container">
                    {renderVerticalMenuItems(menus)}
                </nav>
            ) : (
                <nav className="horizontal-menu-container">
                    <ul className="menu" style={menuStyles}>
                        {renderHorizontalMenuItems(menus)}
                    </ul>
                </nav>
            );
        };

export default Menus;
