export const templateConfig = {
    carousel: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/11700'
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11702'
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11701'
        }
    ],
    menus: [
        {
            templateName: 'Horizontal',
            previewImage: 'https://www.fiklu.com/media/11780'
        },
        {
            templateName: 'Vertical',
            previewImage: 'https://www.fiklu.com/media/11779'
        },
    ],
    cardComponent: [
        {
            templateName: 'Standard Card',
            previewImage: 'https://www.fiklu.com/media/79'
        },
        {
            templateName: 'Fancy Card',
            previewImage: 'https://www.fiklu.com/media/79'
        }
    ],
    heading: [
        {
            templateName: 'Classic-Heading',
            previewImage: 'https://www.fiklu.com/media/11704',
            styles: {
                text: {
                    fontFamily: 'Georgia, serif',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Dotted-Underline-Heading',
            previewImage: 'https://www.fiklu.com/media/11705',
            styles: {
                text: {
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '28px',
                    fontWeight: '500',
                    color: '#444',
                    textDecoration: 'underline dotted',
                    textAlign: 'left',
                },
            },
        },
        {
            templateName: 'Minimal-Heading',
            previewImage: 'https://www.fiklu.com/media/11707',
            styles: {
                text: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '24px',
                    fontWeight: '400',
                    color: '#777',
                    textAlign: 'left',
                    margin: '10px 0',
                },
            },
        },
        {
            templateName: 'Gradient-Heading',
            previewImage: 'https://www.fiklu.com/media/11706',
            styles: {
                text: {
                    fontFamily: 'Helvetica, sans-serif',
                    fontSize: '36px',
                    fontWeight: '600',
                    background: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Shadow-Heading',
            previewImage: 'https://www.fiklu.com/media/11708',
            styles: {
                text: {
                    fontFamily: 'Verdana, sans-serif',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    color: '#222',
                    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                    textAlign: 'center',
                },
            },
        },
        {
            templateName: 'Boxed-Heading',
            previewImage: 'https://www.fiklu.com/media/11703',
            styles: {
                text: {
                    fontFamily: 'Times New Roman, serif',
                    fontSize: '28px',
                    fontWeight: '500',
                    color: '#000',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                    borderRadius: '8px',
                    textAlign: 'center',
                },
            },
        },
    ],

    image: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11719',
        },
        {
            templateName: 'Rounded',
            previewImage: 'https://www.fiklu.com/media/11722',
        },
        {
            templateName: 'Shadow',
            previewImage: 'https://www.fiklu.com/media/11723',
        },
        {
            templateName: 'Framed',
            previewImage: 'https://www.fiklu.com/media/11720',
        },
        {
            templateName: 'Polaroid',
            previewImage: 'https://www.fiklu.com/media/11721',
        },
        {
            templateName: 'Circle-Frame',
            previewImage: 'https://www.fiklu.com/media/11718',
        },
    ],
    button: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11714',
        },
        {
            templateName: 'Outline',
            previewImage: 'https://www.fiklu.com/media/11715',
        },
        {
            templateName: 'Rounded',
            previewImage: 'https://www.fiklu.com/media/11716',
       },
        {
            templateName: 'Gradient',
            previewImage: 'https://www.fiklu.com/media/11713',
        },
        {
            templateName: 'Shadow',
            previewImage: 'https://www.fiklu.com/media/11717',
        },
    ],
    tabs: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11738',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/11737',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11740',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11739',
        },
        {
            templateName: 'Boxed',
            previewImage: 'https://www.fiklu.com/media/11736',
        },
    ],
    accordion: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11741',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11742',
        },
        {
            templateName: 'Outlined',
            previewImage: 'https://www.fiklu.com/media/11743',
        },
    ],

    horizontalCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11764',
        },
        {
            templateName: 'HorizontalCardRight',
            previewImage: 'https://www.fiklu.com/media/11765',
        },
        {
            templateName: 'HorizontalCardTop',
            previewImage: 'https://www.fiklu.com/media/11763',
        },
        {
            templateName: 'HorizontalCardBottom',
            previewImage: 'https://www.fiklu.com/media/11759',
        },
        {
            templateName: 'HorizontalCardRoundedLeft',
            previewImage: 'https://www.fiklu.com/media/11761',
        },
        {
            templateName: 'HorizontalCardRoundedTop',
            previewImage: 'https://www.fiklu.com/media/11762',
        },
    ],
    blogList: [
        {
            templateName: 'default',
            previewImage: 'https://www.fiklu.com/media/11786',
        },
        {
            templateName: 'Slider',
            previewImage: 'https://www.fiklu.com/media/11788',
        },
        {
            templateName: 'Grid',
            previewImage: 'https://www.fiklu.com/media/11787',
        },
    ],
    blogs: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11784',
        },
        {
            templateName: 'SideBySide',
            previewImage: 'https://www.fiklu.com/media/11785',
        },
        {
            templateName: 'Horizontal',
            previewImage: 'https://www.fiklu.com/media/11783',
        },
    ],
    imageOverlayCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11752',
        },
        {
            templateName: 'CenterAlignedOverlay',
            previewImage: 'https://www.fiklu.com/media/11750',
        },
        {
            templateName: 'BottomOverlay',
            previewImage: 'https://www.fiklu.com/media/11751',
        },
        {
            templateName: 'GradientOverlay',
            previewImage: 'https://www.fiklu.com/media/11753',
        },
        {
            templateName: 'TransparentOverlay',
            previewImage: 'https://www.fiklu.com/media/11754',
        },
    ],
    listGroupCard: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11766',
        },
        {
            templateName: 'Outlined',
            previewImage: 'https://www.fiklu.com/media/11770',
        },
        {
            templateName: 'Gradient',
            previewImage: 'https://www.fiklu.com/media/11767',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11769',
        },
        {
            templateName: 'IconOnly',
            previewImage: 'https://www.fiklu.com/media/11768',
        },
    ],
    pricingTable: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/default-pricing-table',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/classic-pricing-table',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/modern-pricing-table',
        },
    ],
    faq: [
        {
            templateName: 'ModernClassic',
            previewImage: 'https://www.fiklu.com/media/faq-modern-classic',
        },
        {
            templateName: 'CollapsibleGradient',
            previewImage: 'https://www.fiklu.com/media/faq-collapsible-gradient',
        },
        {
            templateName: 'BoxedShadow',
            previewImage: 'https://www.fiklu.com/media/faq-boxed-shadow',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/faq-minimal',
        },
        {
            templateName: 'Interactive',
            previewImage: 'https://www.fiklu.com/media/faq-interactive',
        },
    ],
    testimonialComponent: [
        {
            templateName: 'ImageOnTop',
            previewImage: 'https://www.fiklu.com/media/11776',
        },
        {
            templateName: 'SplitScreen',
            previewImage: 'https://www.fiklu.com/media/11777',
        },
        {
            templateName: 'TextOnly',
            previewImage: 'https://www.fiklu.com/media/11778',
        },
        {
            templateName: 'CenterAligned',
            previewImage: 'https://www.fiklu.com/media/11775',
        },
    ],
    teamSection: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.example.com/media/classic-team-template',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-team-template',
        },
        {
            templateName: 'Split',
            previewImage: 'https://www.example.com/media/split-team-template',
        },
        {
            templateName: 'CardOverlay',
            previewImage: 'https://www.example.com/media/card-overlay-team-template',
        },
        {
            templateName: 'MinimalGrid',
            previewImage: 'https://www.example.com/media/minimal-grid-team-template',
        },
    ],
    modal: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/default-modal',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/modern-modal',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/minimal-modal',
        },
    ],

    blockquote: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11710',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11711',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11709',
        },
        {
            templateName: 'Roundedquote',
            previewImage: 'https://www.fiklu.com/media/11712',
        }
    ],
    gallery: [
        {
            templateName: 'Grid',
            previewImage: 'https://www.fiklu.com/media/11725',
        },
        {
            templateName: 'Carousel',
            previewImage: 'https://www.fiklu.com/media/11724',
        },
        {
            templateName: 'Overlay',
            previewImage: 'https://www.fiklu.com/media/11726',
        },
        {
            templateName: 'Lightbox',
            previewImage: 'https://www.fiklu.com/media/11728',
        },
        {
            templateName: 'Slider',
            previewImage: 'https://www.fiklu.com/media/11727',
        },
    ],
    iconCard: [
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11773',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11772',
        },
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/11771',
        },
    ],
    headerFooterCard: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/11755',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11757',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11756',
        },
    ],
    progress: [
        {
            templateName: 'Classic',
            previewImage: 'https://www.fiklu.com/media/11744',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11745',
        },
    ],
    breadcrumb: [
        {
            templateName: 'Default',
            previewImage: 'https://www.fiklu.com/media/11747',
            description: 'Simple breadcrumb with default styling and separators.',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.fiklu.com/media/11748',
            description: 'Modern breadcrumb with rounded backgrounds and clean separators.',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.fiklu.com/media/11749',
            description: 'Modern breadcrumb with rounded backgrounds and clean separators.',
        },
        {
            templateName: 'Custom',
            previewImage: 'https://www.fiklu.com/media/11746',
            description: 'Breadcrumb styled with pill-shaped links for a sleek appearance.',
        },
    ],
    socialMediaIcons: [
        {
            templateName: 'Default',
            previewImage: 'https://www.example.com/media/gallery-grid',
        },
        {
            templateName: 'Modern',
            previewImage: 'https://www.example.com/media/gallery-carousel',
        },
        {
            templateName: 'Minimal',
            previewImage: 'https://www.example.com/media/gallery-overlay',
        },
        {
            templateName: 'Custom',
            previewImage: 'https://www.example.com/media/gallery-polaroid',
        },
    ],
    contactForm: [
        {
            templateName: 'SingleColumn',
            previewImage: 'https://www.fiklu.com/media/11781',
        },
        {
            templateName: 'TwoColumn',
            previewImage: 'https://www.fiklu.com/media/11782',
        },
    ],

};
