import { API_URL } from '../../config';
import Swal from 'sweetalert2';

export const fetchPageData = async (pageId) => {
    const response = await fetch(`${API_URL}/pages-builder/by-id/${pageId}`);
    const data = await response.json();
    return data;
};


export const fetchHeaderFooterData = async (pageId) => {
    const response = await fetch(`${API_URL}/header-footer-builder/by-id/${pageId}`);
    const data = await response.json();
    return data;
};


/**
 * Build the payload for saving page data.
 * @param {string} pageId - The ID of the page.
 * @param {Array} rows - The rows of the page.
 * @returns {Object} - The constructed page data payload.
 */
const buildPageData = (pageId, rows) => ({
    title: 'My New Page',
    pageId,
    rows: rows.map(row => ({
        disabled: row.disabled,
        styles: row.styles,
        sections: row.sections.map(section => ({
            disabled: section.disabled,
            layoutKey: section.columns.map(col => col.class).join('-'),
            columns: section.columns.map(col => ({
                class: col.class,
                disabled: col.disabled,
                styles: col.styles,
                components: col.components.map(component => ({
                    type: component.type,
                    props: component.props,
                    disabled: component.disabled,
                })),
                subsections: col.sections?.map(nestedSection => ({
                    layoutKey: nestedSection.columns.map(nestedCol => nestedCol.class).join('-'),
                    disabled: nestedSection.disabled,
                    styles: nestedSection.styles,
                    subcolumns: nestedSection.columns.map(nestedCol => ({
                        class: nestedCol.class,
                        disabled: nestedCol.disabled,
                        styles: nestedCol.styles,
                        components: nestedCol.components.map(nestedComponent => ({
                            type: nestedComponent.type,
                            props: nestedComponent.props,
                            disabled: nestedComponent.disabled,
                        })),
                    })),
                })) || [],
            })),
        })),
    })),
});

/**
 * Save page data to the server.
 * @param {string} pageId - The page ID.
 * @param {Array} rows - The rows of the page.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const savePageData = async (pageId, rows) => {
    const pageData = buildPageData(pageId, rows);

    try {
        const response = await fetch(`${API_URL}/pages-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pageData),
        });

        if (!response.ok) {
            throw new Error('Failed to save page data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error saving page data:', error);
        throw error;
    }
};

/**
 * Save page data to the server.
 * @param {string} pageId - The page ID.
 * @param {Array} rows - The rows of the page.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const saveHeaderFooterData = async (pageId, rows) => {
    const pageData = buildPageData(pageId, rows);

    try {
        const response = await fetch(`${API_URL}/header-footer-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pageData),
        });

        if (!response.ok) {
            throw new Error('Failed to save page data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error saving page data:', error);
        throw error;
    }
};



export const fetchPostData = async (postId, subdomain) => {
    const response = await fetch(`${API_URL}/post-builder/by-id/${postId}?account_key=${subdomain}`);
    const data = await response.json();
    return data;
};

/**
 * Build the payload for saving page data.
 * @param {string} pageId - The ID of the page.
 * @param {Array} rows - The rows of the page.
 * @returns {Object} - The constructed page data payload.
 */
const buildPostData = (postId, rows) => ({
    title: 'New Post',
    postId,
    rows: rows.map(row => ({
        disabled: row.disabled,
        styles: row.styles,
        sections: row.sections.map(section => ({
            disabled: section.disabled,
            layoutKey: section.columns.map(col => col.class).join('-'),
            columns: section.columns.map(col => ({
                class: col.class,
                disabled: col.disabled,
                styles: col.styles,
                components: col.components.map(component => ({
                    type: component.type,
                    props: component.props,
                    disabled: component.disabled,
                })),
                subsections: col.sections?.map(nestedSection => ({
                    layoutKey: nestedSection.columns.map(nestedCol => nestedCol.class).join('-'),
                    disabled: nestedSection.disabled,
                    styles: nestedSection.styles,
                    subcolumns: nestedSection.columns.map(nestedCol => ({
                        class: nestedCol.class,
                        disabled: nestedCol.disabled,
                        styles: nestedCol.styles,
                        components: nestedCol.components.map(nestedComponent => ({
                            type: nestedComponent.type,
                            props: nestedComponent.props,
                            disabled: nestedComponent.disabled,
                        })),
                    })),
                })) || [],
            })),
        })),
    })),
});

/**
 * Save page data to the server.
 * @param {string} pageId - The page ID.
 * @param {Array} rows - The rows of the page.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const savePostData = async (postId, rows) => {
    const postData = buildPostData(postId, rows);

    try {
        const response = await fetch(`${API_URL}/post-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
            throw new Error('Failed to save post data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error saving post data:', error);
        throw error;
    }
};


/**
 * Save a single component as a reusable template.
 * Prompts the user for a template name (using SweetAlert2) and then posts to the backend.
 *
 * @param {Object} component - The component object containing `type` and `props`.
 */
export const handleSaveAsTemplate = async (component, subdomain) => {
  try {
    const { value: templateName } = await Swal.fire({
      title: 'Enter a name for this template',
      input: 'text',
      inputPlaceholder: 'My Template',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Template name cannot be empty!';
        }
        return null;
      },
    });

    // If user cancels or doesn't input a name, templateName will be undefined
    if (!templateName) return;

    // Construct the data you want to send to the server
    const templateData = {
      name: templateName,
      type: component.type,
      props: component.props,
      // Include any additional fields your backend requires
    };

    // Make the POST request to your backend
    const response = await fetch(`${API_URL}/templates?account_key=${subdomain}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // If your API needs authorization, include it here:
        // Authorization: 'Bearer <your-token>'
      },
      body: JSON.stringify(templateData),
    });

    if (!response.ok) {
      throw new Error(`Failed to save template: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Template saved successfully:', result);

    // Alert success with SweetAlert2
    Swal.fire({
      icon: 'success',
      title: 'Template saved!',
      text: `Template "${templateName}" saved successfully.`,
      timer: 2000,
      showConfirmButton: false,
    });
  } catch (error) {
    console.error('Error saving template:', error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Failed to save template. Check console for details.',
    });
  }
};


export const handleSaveRowAsTemplate = async (row, subdomain) => {
  try {
    const { value: templateName } = await Swal.fire({
      title: 'Enter a name for this row template',
      input: 'text',
      inputPlaceholder: 'My 2-Column Hero Row',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Template name cannot be empty!';
        }
      },
    });

    if (!templateName) return;

    // Prepare the data
    const rowTemplateData = {
      name: templateName,
      row_data: row, // The entire row object 
    };

    // POST to your backend
    const response = await fetch(`${API_URL}/row-templates?account_key=${subdomain}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rowTemplateData),
    });

    if (!response.ok) {
      throw new Error('Failed to save row template');
    }

    const result = await response.json();
    console.log('Row template saved successfully:', result);

    Swal.fire({
      icon: 'success',
      title: 'Row Template saved!',
      text: `Row template "${templateName}" saved successfully.`,
      timer: 2000,
      showConfirmButton: false,
    });
  } catch (error) {
    console.error('Error saving row template:', error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Failed to save row template. Check console for details.',
    });
  }
};