import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { API_URL } from '../../../../../config';
import { useAppData } from '../../../AppDataContext';

const TwoColumnContactForm = ({
  formFields,
  formName,
  buttonText,
  styles,
  onSubmit,
  validationMessages, // object with keys: required, email, number
  successMessage      // custom success message string
}) => {
  const { subdomain } = useAppData();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fieldData = {};
    const errors = [];

    formFields.forEach((field) => {
      const value = e.target[field.name].value;
      fieldData[field.name] = value;

      if (field.required && (!value || value.trim() === '')) {
        const msg = validationMessages && validationMessages.required 
          ? validationMessages.required.replace('$field', field.label || field.name)
          : `${field.label || field.name} is required.`;
        errors.push(msg);
      } else if (value) {
        if (field.type === 'email') {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            const msg = validationMessages && validationMessages.email 
              ? validationMessages.email.replace('$field', field.label || field.name)
              : `${field.label || field.name} must be a valid email address.`;
            errors.push(msg);
          }
        }
        if (field.type === 'number' && isNaN(Number(value))) {
          const msg = validationMessages && validationMessages.number 
            ? validationMessages.number.replace('$field', field.label || field.name)
            : `${field.label || field.name} must be a valid number.`;
          errors.push(msg);
        }
      }
    });

    if (errors.length > 0) {
      Swal.fire({
        title: 'Validation Error',
        html: errors.join('<br/>'),
        icon: 'error'
      });
      return;
    }

    const finalFormName = formName || 'Contact Form';

    const payload = {
      data: fieldData,
      form_name: finalFormName
    };

    try {
      const response = await fetch(`${API_URL}/contact-submissions?account_key=${subdomain}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to submit form');
      }
      const result = await response.json();
      Swal.fire({
        title: 'Success',
        text: successMessage || 'Form submitted successfully!',
        icon: 'success'
      });
      // Clear the form inputs after successful submission.
      e.target.reset();
      if (onSubmit) onSubmit(result);
    } catch (error) {
      Swal.fire({
        title: 'Submission Error',
        text: error.message || 'There was an error submitting the form.',
        icon: 'error'
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={styles.container}>
      <Row>
        {formFields.map((field, index) => {
          const controlProps =
            field.type === 'textarea'
              ? { as: 'textarea' }
              : { type: field.type || 'text' };

          return (
            <Col md={6} key={index}>
              <Form.Group
                className="two-contact-form-wrapper"
                controlId={`form_${field.name}`}
              >
                <Form.Label style={styles.labels}>{field.label}</Form.Label>
                <Form.Control
                  name={field.name}
                  placeholder={field.placeholder}
                  style={styles.inputs}
                  {...controlProps}
                />
              </Form.Group>
            </Col>
          );
        })}
      </Row>
      <Button
        variant="primary"
        className="two-contact-btn"
        type="submit"
        style={styles.button}
      >
        {buttonText || 'Submit'}
      </Button>
    </Form>
  );
};

export default TwoColumnContactForm;
