// styleConfig.js

import {
  Typography,
  Spacing,
  Borders,
  Colors,
  Layout,
  Links,
  Opacity,
  ColorOnly,
  Alignment,
  Visibility, 
  Background,
  OverlayStyle
} from './styleCategories';

export const styleConfig = {
  iconCard: {
    iconWrap: [...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Typography, ...Colors, ...Spacing, ...Layout, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    description: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Typography, ...Colors, ...Borders, ...Spacing, ...Links, ...Visibility],
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  iconComponent: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    iconWrap: [...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Typography, ...Colors, ...Spacing, ...Layout, ...Visibility],
  },  
  heading: {
    text: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
  },
  blogPostTitle: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    text: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
  },
  blogPostExcerpt: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    text: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
  },
  blogPostBody: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility,   'fontFamily'],
  },
  image: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    style: [...Layout, ...Borders, ...Spacing, ...Colors, ...Links, ...Visibility],
  },
  brandLogo: {
    style: [...Layout, ...Borders, ...Spacing, ...Colors, ...Links, ...Visibility,  ...Background],
  },
  faq: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    question: [...Typography, ...Colors, ...Visibility],
    answer: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  socialMediaIcons: {
    container: [...Layout, ...Spacing, ...Colors, ...Visibility],
    iconContainer: [...Layout, ...Spacing, ...Colors, ...Visibility],
    icon: [...Layout, ...Colors, ...ColorOnly, ...Borders, ...Spacing, ...Visibility],
  },
  text: { // Configuration for TextComponent
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    content: [...Typography, ...Visibility, ...Spacing],
  },
  richText: { // Configuration for RichTextComponent
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    content: [...Typography, ...Visibility],
  },
  button: {
    container: [
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Alignment,
      ...Visibility,
    ],
    content: [
      ...Colors,
      ...Typography,
      ...Borders,
      ...Spacing,
      ...Visibility,
      ...Links
    ],
  },
  cardComponent: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Layout, ...Borders, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  imageOverlayCard: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  headerFooterCard: {
    header: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    footer: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
  horizontalCard: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    cardBody: [...Spacing, ...Colors, ...Borders, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Borders, ...Spacing, ...Visibility, ...Layout],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
 pricingTable: {
    // Outermost container (e.g., row wrapper) for the entire pricing table
    container: [...Spacing, ...Colors, ...Borders, ...Layout, ...Visibility],

    // Wrapper around each individual plan column/card (e.g., the "col-md-4")
    planWrapper: [...Spacing, ...Colors, ...Borders, ...Layout, ...Visibility],

    // The card itself (e.g., .card or .classic-card)
    card: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],

    // Header area of the card (.card-header)
    header: [...Spacing, ...Typography, ...Colors, ...Borders, ...Visibility],

    // The plan name text (e.g., "Basic", "Pro", etc.)
    planName: [...Typography, ...Colors, ...Spacing, ...Visibility],

    // The price text (e.g., "$29/month")
    price: [...Typography, ...Colors, ...Spacing, ...Visibility],

    // UL that contains the features
    features: [...Typography, ...Colors, ...Spacing, ...Visibility],

    // Each LI feature item
    featureItem: [...Typography, ...Colors, ...Spacing, ...Visibility],
    iconContainer: [...Spacing, ...Colors, ...Borders, ...Layout, ...Visibility],

    // Icon style (e.g., checkmark beside each feature)
    icon: [...ColorOnly, ...Visibility],

    // Footer area of the card (.card-footer)
    footer: [...Spacing, ...Colors, ...Borders, ...Visibility],

    // The CTA (Sign Up / Select) button
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
  testimonialComponent: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    image: [...Borders, ...Spacing, ...Visibility, ...Layout],
    name: [...Typography, ...Colors, ...Spacing, ...Visibility],
    position: [...Typography, ...Colors, ...Spacing, ...Visibility],
    message: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  blockquote: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    blockquote: [...Typography, ...Borders, ...Visibility],
    footer: [...Typography, ...Borders, ...Visibility],
  },
  divider: {
    divider: [...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  alert: {
    alertStyle: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  video: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility, ...Layout],
  },
  badge: {
    badgeStyle: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  spacer: {
    spacerStyle: [
      'height', // Assuming 'height' isn't covered by existing categories
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Visibility,
    ],
  },
  teamSection: {
    container: [...Borders, ...Spacing, ...Colors, ...Visibility],
    image: [...Borders, ...Visibility, ...Layout],
    name: [...Typography, ...Colors, ...Spacing, ...Visibility],
    position: [...Typography, ...Colors, ...Spacing, ...Visibility],
    icon: [...Colors, ...ColorOnly, ...Spacing, ...Visibility],
  },
  featureCards: {
    headline: [...Typography, ...Colors, ...Spacing, ...Visibility],
    description: [...Typography, ...Colors, ...Spacing, ...Visibility],
    card: [...Borders, ...Layout, ...Spacing, ...Visibility],
    image: [...Borders, ...Layout, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    text: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  tabs: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility],
    tabItem: [...Typography, ...Colors, ...Borders, ...Spacing, ...Visibility],
    activeItem: [...Typography, ...Colors, ...Borders, ...Spacing, ...Visibility],
    tabContent: [...Colors, ...Spacing, ...Borders, ...Typography, ...Visibility],
  },
  accordion: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility],
    header: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
    body: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  listGroupCard: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility, ...Background],
    header: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    item: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Colors, ...ColorOnly, ...Spacing, ...Visibility],
  },
  modal: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    body: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
    closeButton: [...Colors, ...Typography, ...Borders, ...Visibility],
    overlay: [...Colors, ...Opacity, ...Spacing, ...Visibility],
  },
  timeline: {
    date: [...Typography, ...Colors, ...Spacing, ...Visibility],
    heading: [...Typography, ...Colors, ...Spacing, ...Visibility],
    body: [...Typography, ...Colors, ...Spacing, ...Visibility],
    panel: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  progress: {
    title: [...Typography, ...Colors, ...Visibility],
    bar: [...Colors, ...Borders, ...Visibility],
    fill: [...Colors, ...Borders, ...Visibility],
    percentage: [...Typography, ...Colors, ...Visibility],
  },
  breadcrumb: {
    container: [ ...Spacing, ...Borders, ...Alignment, ...Visibility, ...Background],
    separator: [...Typography, ...Spacing, ...Visibility],
    activeItem: [...Typography, ...Spacing, ...Visibility],
    link: [...Typography, ...Spacing, ...Visibility],
  },
  carousel: {
    headline: [...Typography, ...Colors, ...Spacing, ...Visibility],
    caption: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Borders, ...Spacing, ...Layout, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  contactForm: {
    container: [...Colors, ...Spacing, ...Borders, ...Visibility],
    labels: [...Typography, ...Colors, ...Spacing, ...Visibility],
    inputs: [...Typography, ...Colors, ...Spacing, ...Borders, ...Layout, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Layout, ...Visibility],
  },
  blogList: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    card: [...Colors, ...Spacing, ...Layout, ...Visibility, ...Borders],
    title: [...Typography, ...Colors, ...Visibility, ...Spacing],
    content: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
    image: [...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  blogs: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    card: [...Colors, ...Spacing, ...Layout, ...Visibility, ...Borders],
    title: [...Typography, ...Colors, ...Visibility, ...Spacing],
    content: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
    filter: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
    activeFilter: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
    image: [...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  gallery: {
    container: [...Borders, ...Spacing, ...Visibility],
    slide: [...Colors, ...Borders, ...Spacing, ...Visibility, ...Layout],
    image: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility, ...Background],
  },
  menus: {
    menu: [
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Layout,
      ...Visibility,
    ],
    menuItem: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
    menuItemLink: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
    subMenu: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Layout,
      ...Visibility,
    ],
    dropdownItem: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
   dropdownItemStylesLink: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
    toggle: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Layout,
      ...Visibility,
    ],
  },
};
