import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook, 
  faTwitter, 
  faInstagram, 
  faLinkedin, 
  faSnapchat, 
  faYoutube, 
  faPinterest, 
  faReddit 
} from '@fortawesome/free-brands-svg-icons';

const iconMapping = {
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
  snapchat: faSnapchat,
  youtube: faYoutube,
  pinterest: faPinterest,
  reddit: faReddit,
};

const CardOverlayTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
  <div className="card-overlay-wrap" style={styles.container}>
    <div className="row g-4 justify-content-center">
      {teamMembers.map((member, idx) => (
        <div key={idx} className="col-md-4">
          <Card
            className="team-member-card"
            style={{
              backgroundImage: `url(${member.image})`,
              ...styles.image,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Card.ImgOverlay>
              <Card.Title style={styles.name}>{member.name}</Card.Title>
              <Card.Text style={styles.position}>{member.position}</Card.Text>
              <div className="social-media-icons">
                {member.socialMedia && member.socialMedia.length > 0
                  ? member.socialMedia.map((social, index) => {
                      const iconToUse = iconMapping[social.icon];
                      return (
                        <a
                          key={index}
                          href={social.url}
                          className="icon-link"
                          style={styles.icon}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={iconToUse} />
                        </a>
                      );
                    })
                  : null}
              </div>
            </Card.ImgOverlay>
          </Card>
        </div>
      ))}
    </div>
  </div>
);

export default CardOverlayTeamTemplate;
